import Vue from 'vue';

const directives = {
  affix: {
    bind(el, binding) {
      let wrapperDom = document.querySelector('.global-layout__main__container');
      if(binding.value) {
        wrapperDom = document.querySelector(binding.value);
      }
      wrapperDom.onscroll = (e) =>{
        console.log(e.target.offsetTop);
      }
    }
  },
};

Object.keys(directives).forEach((name) => {
  Vue.directive(name, directives[name]);
});
