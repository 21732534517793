export default [
  {
    path: '/equipment/product-pricing',
    name: 'productpricing',
    component: () => import('@/views/Equipment/ProductPricing/List'),
    meta: {
      title: 'lang.cpq.menu.product.equipment.quote', // 产品定价
    },
  },
  // 产品定价-审批列表
  {
    name: 'Approvalist',
    path: '/equipment/product-pricing/approval',
    component: () => import('@/views/Equipment/Approvallist/List'),
    meta: {
      title: 'lang.cpq.menu.product.equipment.quote.approve', // 产品定价审批
    },
  },
  // //产品定价点击之后页面
  {
    path: '/equipment/pricing-transfer',
    name: 'pricingtransfer',
    component: () => import('@/views/Equipment/ProductPricing/pricingtransfer'),
  },
  // 产品定价- 标准产品查看
  {
    name: 'vistandard',
    path: '/equipment/vistandard',
    component: () => import('@/views/Equipment/ProductPricing/componten/vistandard'),
    meta: {
      title: 'lang.cpq.menu.product.equipment.quote.approve', // 产品定价审批
      breadcrumb: [
        { title: 'lang.cpq.menu.product.equipment.quote', path: '/equipment/product-pricing' }, // 产品定价
        { title: 'lang.cpq.menu.product.equipment.quote.approve' }], // 产品定价审批
    },
  },
  // 产品定价- 非标准产品查看
  {
    name: 'vistandard',
    path: '/equipment/vinostandard',
    component: () => import('@/views/Equipment/ProductPricing/componten/vinostandard'),
    meta: {
      title: 'lang.cpq.menu.product.equipment.quote.approve', // 产品定价审批
      breadcrumb: [
        { title: 'lang.cpq.menu.product.equipment.quote', path: '/equipment/product-pricing' }, // 产品定价
        { title: 'lang.cpq.menu.product.equipment.quote.approve' }], // 产品定价审批
    },
  },
  // 产品定价- 自制备件查看
  {
    name: 'vihomemade',
    path: '/equipment/vihomemade',
    component: () => import('@/views/Equipment/ProductPricing/componten/vihomemade'),
    meta: {
      title: 'lang.cpq.label.processing004', // 自制件半成品
      breadcrumb: [
        { title: 'lang.cpq.menu.product.equipment.quote', path: '/equipment/product-pricing' }, // 产品定价
        { title: 'lang.cpq.label.processing004' }], // 自制件半成品
    },
  },
  // 产品定价- 标准产品编辑
  {
    name: 'standard',
    path: '/equipment/product-standard',
    component: () => import('@/views/Equipment/ProductPricing/standard'),
    meta: {
      title: 'lang.cpq.label.preparedparts02', // 标准产品定价
      breadcrumb: [
        { title: 'lang.cpq.menu.equipmet.price.to.do.list', path: '/equipment/price-to-do' }, // 价格待办
        { title: 'lang.cpq.label.preparedparts02' }], // 标准产品定价
    },
  },
  // 产品定价- 备件管理
  {
    name: 'standard',
    path: '/equipment/othermaterials',
    component: () => import('@/views/Equipment/ProductPricing/othermaterials'),
    meta: {
      title: 'lang.cpq.label.preparedparts07', // 备件管理
      breadcrumb: [
        { title: 'lang.cpq.menu.equipmet.price.to.do.list', path: '/equipment/price-to-do' }, // 价格待办
        { title: 'lang.cpq.label.preparedparts07' }], // 备件管理
    },
  },
  // 产品定价- 产品定价中转页面
  {
    name: 'standard',
    path: '/equipment/preparedparts',
    component: () => import('@/views/Equipment/ProductPricing/preparedparts.vue'),
    meta: {
      title: 'lang.cpq.menu.product.equipment.quote', // 产品定价
      breadcrumb: [
        { title: 'lang.cpq.menu.equipmet.price.to.do.list', path: '/equipment/price-to-do' }, // 价格待办
        { title: 'lang.cpq.menu.product.equipment.quote' }], // 产品定价
    },
  },
  // 产品定价- 非标准产品编辑
  {
    name: 'nostandard',
    path: '/equipment/product-nostandard',
    component: () => import('@/views/Equipment/ProductPricing/nostandard'),
    meta: {
      title: 'lang.cpq.label.estimateddiscount08', // 非标准产品定价
      breadcrumb: [
        { title: 'lang.cpq.menu.equipmet.price.to.do.list', path: '/equipment/price-to-do' }, // 价格待办
        { title: 'lang.cpq.label.estimateddiscount08' }], // 非标准产品定价
    },
  },
  // 产品定价- 自制半成品
  {
    name: 'standard',
    path: '/equipment/homemade',
    component: () => import('@/views/Equipment/ProductPricing/Homemade'),
    meta: {
      title: 'lang.cpq.label.estimateddiscount08', // 非标准产品定价
      breadcrumb: [
        { title: 'lang.cpq.menu.equipmet.price.to.do.list', path: '/equipment/price-to-do' }, // 价格待办
        { title: 'lang.cpq.menu.updatespareparts04' }], // 自制半成品
    },
  },
];
// {
//     path: 'price-to-do',
//         name: 'priceTodo',
//     component: () => import('@/views/Equipment/Price/List.vue'),
//     meta: {
//     title: 'lang.cpq.menu.equipmet.price.to.do.list',
//         breadcrumb: [{ title: 'lang.cpq.menu.equipmet', path: '/equipment/price-to-do' }, { title: 'lang.cpq.menu.equipmet.price.to.do.list' }],
// },
// },
