import Vue from 'vue';
import moment from "moment";

const filters = {
  format(inputValue, defaultResult = '--', type = 'YYYY-MM-DD HH:mm:ss') {
    if (!inputValue) {
      return defaultResult;
    }
    return moment(Number(inputValue)).format(type);
  },
  formatDate(inputValue, defaultResult = '--', type = 'YYYY-MM-DD') {
    if (!inputValue) {
      return defaultResult;
    }
    if (Number(inputValue)) {
      return moment(Number(inputValue)).format(type);
    }
    return moment(inputValue).format(type);
  },
  formatNumber(inputValue, defaultResult = '0') {
    if (isNaN(String(inputValue)) || inputValue === '') {
      return defaultResult
    }
    let numberList = Math.abs(inputValue).toFixed(2).split('.');
    /*if(!numberList[1] || numberList[1].length < 2) {
      numberList = Math.abs(inputValue).toFixed(2).split('.')
    }*/
    if (numberList[0].length > 3) {
      const strList = numberList[0].split('').reverse();

      const result = [];
      strList.forEach((item, index) => {
        result.push(item);
        if ((index + 1) % 3 === 0 && index !== strList.length - 1) {
          result.push(',')
        }
      });
      const resultStr = inputValue < 0 ? `-${result.reverse().join('')}` : result.reverse().join('');
      return `${resultStr}.${numberList[1]}`
    }
    return inputValue < 0 ? `-${numberList.join('.')}` : numberList.join('.');
  },
  formatNumberEight(inputValue, defaultResult = '0') {
    if (isNaN(String(inputValue)) || inputValue === '') {
      return defaultResult
    }
    let numberList = Math.abs(inputValue).toFixed(8).split('.');
    /*if(!numberList[1] || numberList[1].length < 2) {
      numberList = Math.abs(inputValue).toFixed(2).split('.')
    }*/
    if (numberList[0].length > 3) {
      const strList = numberList[0].split('').reverse();

      const result = [];
      strList.forEach((item, index) => {
        result.push(item);
        if ((index + 1) % 3 === 0 && index !== strList.length - 1) {
          result.push(',')
        }
      });
      const resultStr = inputValue < 0 ? `-${result.reverse().join('')}` : result.reverse().join('');
      return `${resultStr}.${numberList[1]}`
    }
    return inputValue < 0 ? `-${numberList.join('.')}` : numberList.join('.');
  },
  formatNumberDynamic(inputValue, defaultResult = '0') {
    if (isNaN(String(inputValue)) || inputValue === '') {
      return defaultResult
    }
    const fractionList = inputValue.split('.')
    let fractionDigits = 2;
    let fraction = fractionList[1] || '';
    while (fraction.endsWith('0')) {
      fraction = fraction.slice(0, fraction.length - 1);
    }
    fractionDigits = Math.max(fractionDigits, fraction.length);
    let numberList = Math.abs(inputValue).toFixed(fractionDigits).split('.');
    /*if(!numberList[1] || numberList[1].length < 2) {
      numberList = Math.abs(inputValue).toFixed(2).split('.')
    }*/
    if (numberList[0].length > 3) {
      const strList = numberList[0].split('').reverse();

      const result = [];
      strList.forEach((item, index) => {
        result.push(item);
        if ((index + 1) % 3 === 0 && index !== strList.length - 1) {
          result.push(',')
        }
      });
      const resultStr = inputValue < 0 ? `-${result.reverse().join('')}` : result.reverse().join('');
      return `${resultStr}.${numberList[1]}`
    }
    return inputValue < 0 ? `-${numberList.join('.')}` : numberList.join('.');
  },
};

Object.keys(filters).forEach((filter) => {
  Vue.filter(filter, filters[filter]);
});

export default filters;
