export default [
  {
    path: 'write-off',
    name: 'writeOff',
    component: () => import('@/views/Base/Base'),
    meta: {
      title: 'lang.cpq.label.writeOffApplication', // 冲销申请
      icon: 'approve',
    },
    children: [
      // 创建 / 修改 / 详情 / 审批 国内 (冲销)
      {
        path: 'china-create',
        name: 'chinaWriteOffCreate',
        component: () => import('@/views/WriteOff/china/Create'),
        meta: {
          title: 'lang.cpq.label.writeOffApplication',
          breadcrumb: [{
            title: 'lang.cpq.label.invoiceList',
            path: '/invoice/list',
          }, {
            title: 'lang.cpq.label.writeOffApplication',
          }],
        },
      },
      // 创建 / 修改 / 详情 / 审批 海外
      {
        path: 'oversea-create',
        name: 'overseaWriteOffCreate',
        component: () => import('@/views/WriteOff/oversea/Create'),
        meta: {
          title: 'lang.cpq.label.writeOffApplication',
          breadcrumb: [{
            title: 'lang.cpq.label.invoiceList',
            path: '/invoice/list',
          }, {
            title: 'lang.cpq.label.writeOffApplication',
          }],
        },
      },
      // 作废
      {
        path: 'oversea-create-void',
        name: 'overseaWriteOffCreateVoid',
        component: () => import('@/views/WriteOff/oversea/CreateVoid'),
        meta: {
          title: 'lang.cpq.label.voidApplication',
          breadcrumb: [{
            title: 'lang.cpq.label.invoiceList',
            path: '/invoice/list',
          }, {
            title: 'lang.cpq.label.voidApplication',
          }],
        },
      },
    ],
  },
];
