<template>
  <div class="s-p-steps">
    <div class="steps">
      <div v-for="(item,index) in steps"
           class="step step_r"
           :key="index"
           :class="{'step-start': index === 0, 'step-end': index === steps.length - 1, 'active': index < current}">
        <div class="box">
          <div class="rotateBox"></div>
        </div>
        <div class="step_b">
          <div class="title">{{item}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    steps: {
      type: Array,
    },
    current: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {};
  },
  computed: {},
  methods: {},
  mounted() {},
  watch: {},
};
</script>

<style lang='scss' scoped>
  .s-p-steps {
    width: 100%;
    display: flex;
    justify-content: center;
    .steps {
      width: 30%;
      display: flex;
      margin: 0 10px 10px 0;
      zoom: 1;
    }
    .steps::after {
      content: ".";
      display: block;
      clear: both;
      visibility: hidden;
      font-size: 0;
      height: 0;
      line-height: 0;
    }
    .step-start {
      border-radius: 14px 0 0 14px;
    }
    .step-end {
      border-radius: 0 14px 14px 0;
    }
    .step {
      flex: 1;
      height: 28px;
      background: #BED2FF;
      display: flex;
      align-items: center;
      position: relative;
      .box {
        position:absolute;
        right:-45px;
        height:80px;
        width:80px;
        /*background-color: #42b983;*/
        overflow:hidden;
      }
      .step_b {
        width: 100%;
        display: flex;
        justify-content: center;
        padding: 0 30px 0 30px;
        .title {
          display: flex;
          align-items: center;
          height: 14px;
          font-family: SourceHanSansCN-Regular;
          font-size: 14px;
          color: #FFFFFF;
          font-weight: 400;
          white-space: nowrap;
        }
      }
    }
    //旋转的小方形盒子
    .rotateBox {
      content: "";
      background-clip: content-box;
      border:3px solid #fff;
      border-bottom: none;
      border-left: none;
      background-color: #BED2FF;
      height: 24px;
      width: 24px;
      position: absolute;
      display: block;
      top: 28px;
      right: 32px;
      z-index: 1;
      transform:rotate(45deg);
    }
    .active {
      background: #4E80F5  !important;
    }
    //被激活的步骤条的样式
    .active .rotateBox {
      background-color: #4E80F5;
    }
    //最后一步取消向右箭头
    .step:last-child .rotateBox{
      content: "";
      border: none;
      height: 0;
      width: 0;
      z-index: 11;
    }
  }
</style>
