export default {
  namespaced: true,
  state: {
    trialSearchParams: {
      businessId: '',
      opptName: '',
    },
    planSearchParams: {
      businessId: '',
      opptName: '',
    },
  },
  mutations: {
    updateState(state, payload) {
      Object.assign(state, payload);
    },
    updateTrialSearchParams(state, payload) {
      state.trialSearchParams[payload.name] = payload.value;
    },
    updatePlanSearchParams(state, payload) {
      state.planSearchParams[payload.name] = payload.value;
    },
  },
  actions: {},
};
