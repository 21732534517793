<template>
  <div id="app" :class="upperLang">
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>

    <router-view v-if="!$route.meta.keepAlive"></router-view>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import { getParameterByName } from '@/components/SPComponents/utils/util';

export default {
  data() {
    return {
      name: '123',
    };
  },
  created() {
    const hideMenu = getParameterByName('hideMenu') === 'true';
    if (hideMenu) {
      this.setHideMenu(true);
    }
  },
  mounted() {
    window.addEventListener('resize', () => {
      this.updateState({
        windowSize: {
          width: window.innerWidth,
          height: window.innerHeight,
        },
      });
    });
    this.updateState({
      windowSize: {
        width: window.innerWidth,
        height: window.innerHeight,
      },
    });
    this.$Message.config({
      duration: 5,
    });
  },
  computed: {
    ...mapState('app', ['upperLang']),
  },
  methods: {
    ...mapMutations('app', ['updateState', 'setHideMenu']),
  },
};
</script>

<style lang="scss">
  @import "~@/scss/reset.scss";
  @import "~@/scss/variable.scss";
  @import "~@/scss/global.scss";

  #app {
    background: $default-bg;
    min-height: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
  }
</style>
