export default [
  {
    path: 'additional-quotation',
    name: 'additionalQuotation',
    component: () => import('@/views/Base/Base'),
    meta: {
      title: 'lang.cpq.label.additionalQuotation', // 增补报价单
      icon: 'approve',
    },
    children: [
      // 列表
      {
        path: 'list',
        name: 'additionalQuotationList',
        component: () => import('@/views/AdditionalQuotation/List'),
        meta: {
          title: 'lang.cpq.menu.equipment.list.template',
          breadcrumb: [{
            title: 'lang.cpq.label.additionalQuotation',
            path: '/additional-quotation/list',
          }, {
            title: 'lang.cpq.label.additionalQuotationList',
          }],
        },
      },
      // 创建 / 修改 / 详情
      {
        path: 'create',
        name: 'additionalQuotationCreate',
        component: () => import('@/views/AdditionalQuotation/Create'),
        meta: {
          title: 'lang.cpq.menu.equipment.list.template',
          breadcrumb: [{
            title: 'lang.cpq.label.additionalQuotation',
            path: '/additional-quotation/list',
          }, {
            title: 'lang.cpq.label.additionalQuotation',
          }],
        },
      },
    ],
  },
];
