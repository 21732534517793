<template>
  <div class="s-p-search-box-wrap" :style="{height: isShowBtn ? '80px' : 'auto'}">
    <div class="s-p-search-box" :style="boxStyle">
      <Row ref="node">
        <slot></slot>
      </Row>
      <div class="search-btns" v-if="isShowBtn">
        <Button @click="handleReset" :loading="loading">{{$t(resetTxt)}}</Button>
        <Button @click="handleSearch" :loading="loading" type="primary">{{$t(searchTxt)}}</Button>
        <Button v-if="allSpanNum > 24"
                :type="isOpen ? 'primary' : 'default'"
                @click="switchOpen" class="switch-btn" shape="circle">
          <Icon type="ios-more" />
        </Button>
      </div>
    </div>
  </div>

</template>

<script>
import './style.scss';
import i18n from '@/i18n/i18n';

export default {
  name: 'SearchBox',
  provide() {
    return {
      enterClick: this.enterClick,
    };
  },
  data() {
    return {
      isOpen: false,
      allSpanNum: 0,
    };
  },
  props: {
    searchTxt: {
      type: String,
      default: 'lang.cpq.button.search',
    },
    resetTxt: {
      type: String,
      default: 'lang.cpq.button.reset',
    },
    isShowBtn: {
      type: Boolean,
      default: true,
    },
    paddingRight: {
      type: Number,
      default: 150,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    enterClick: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    switchOpen() {
      this.isOpen = !this.isOpen;
    },
    handleReset() {
      this.$emit('on-reset');
    },
    handleSearch() {
      if (this.loading) {
        return;
      }
      this.$emit('on-search');
    },
  },
  computed: {
    boxStyle() {
      let paddingRight = this.allSpanNum > 24 ? `${this.paddingRight + 50}px` : `${this.paddingRight}px`;
      paddingRight = this.isShowBtn ? paddingRight : '0';
      let height = '80px';
      if (this.isOpen || !this.isShowBtn) {
        height = `${Math.ceil(this.allSpanNum / 24) * 80}px`;
      }
      return {
        height,
        paddingRight,
        position: this.isShowBtn ? 'absolute' : 'relative',
      };
    },
  },
  mounted() {
    if (this.$refs.node.$children.length) {
      this.$refs.node.$children.forEach((item) => {
        this.allSpanNum += item.colSpan;
      });
    }
  },
};
</script>
