export default [
  {
    path: 'quotation',
    name: 'quotation',
    component: () => import('@/views/Quotation/Index.vue'),
    meta: {
      title: 'lang.cpq.menu.quotation.list', // 创建报价
      icon: 'apply',
    },
  },
  {
    path: 'quotationReport',
    name: 'quotationReport',
    component: () => import('@/views/Quotation/Report/List.vue'),
    meta: {
      title: 'lang.cpq.menu.quotation.Report.list', // 创建报价
      icon: 'apply',
    },
  },
  {
    path: 'quotation/trial/edit/:opptSn?',
    name: 'quotationTrialEdit',
    component: () => import('@/views/Quotation/Trial/Create.vue'),
    meta: {
      title: 'lang.cpq.action.sheet', // 编辑报价单
      breadcrumb: [
        { title: 'lang.cpq.menu.quotation.list', path: '/quotation' }, // 创建报价
        { title: 'lang.cpq.action.label1' }], // 试算报价
    },
  },
  {
    path: 'quotation/plan/edit/:opptSn?',
    name: 'quotationPlanEdit',
    component: () => import('@/views/Quotation/Plan/Create.vue'),
    meta: {
      title: 'lang.cpq.action.sheet', // 编辑报价单
      breadcrumb: [
        { title: 'lang.cpq.menu.quotation.list', path: '/quotation' }, // 创建报价
        { title: 'lang.cpq.action.label2' }], // 正式报价
    },
  },
  {
    path: 'quotation/plan/view/:id?',
    name: 'quotationPlanView',
    component: () => import('@/views/Quotation/Plan/Create.vue'),
    meta: {
      title: 'lang.cpq.business.label82', // 查看报价单
      breadcrumb: [
        { title: 'lang.cpq.menu.quotation.list', path: '/quotation' }, // 创建报价
        { title: 'lang.cpq.action.label2' }], // 正式报价
    },
  },
  // 规划报价单明细
  {
    path: 'quotationReport/plan/view/:id',
    name: 'quotationReportItem',
    component: () => import('@/views/Quotation/Plan/Create.vue'),
    meta: {
      title: 'lang.cpq.business.label64',
      icon: 'calendar',
      breadcrumb: [
        { title: 'lang.cpq.menu.quotation.Report.list', path: '/quotationReport' }, // 创建报价
        { title: 'lang.cpq.quotation.label342' }, // 物料配置
      ],
    },
  },
  {
    path: 'earlyImplementation',
    name: 'earlyImplementation',
    component: () => import('@/views/EarlyImplementation/List.vue'),
    meta: {
      title: 'lang.cpq.menu.earlyImplementationApply', // 创建报价
      icon: 'apply',
    },
  },
  {
    path: 'earlyImplementationCreate',
    name: 'earlyImplementationCreate',
    component: () => import('@/views/EarlyImplementation/Create.vue'),
    meta: {
      title: 'lang.cpq.menu.earlyImplementationApply', // 提前实施申请
      icon: 'apply',
    },
  },
  {
    path: 'quotationAllReport',
    name: 'quotationAllReport',
    component: () => import('@/views/Quotation/Report/AllList.vue'),
    meta: {
      title: 'lang.cpq.menu.generalDetailsOfQuotation', // 报价单总明细
      icon: 'apply',
      keepAlive: true,
    },
  },
  // 渠道报价明细
  {
    path: 'quotation-channel-report',
    name: 'quotationChannelReport',
    component: () => import('@/views/Quotation/Report/ChannelList.vue'),
    meta: {
      title: 'lang.cpq.menu.channelQuoteDetail', // 渠道报价明细
      icon: 'apply',
    },
  },
  // 预报价
  {
    path: 'pre-quotation/plan/edit/:opptSn?',
    name: 'preQuotationPlanEdit',
    component: () => import('@/views/Quotation/PrePlan/Create.vue'),
    meta: {
      title: 'lang.cpq.action.sheet', // 编辑报价单
      breadcrumb: [
        { title: 'lang.cpq.menu.quotation.list', path: '/quotation' }, // 创建报价
        { title: 'lang.cpq.label.preQuote' }], // 预报价
    },
  },
  // 预报价
  {
    path: 'pre-quotation/plan/view/:id?',
    name: 'preQuotationPlanView',
    component: () => import('@/views/Quotation/PrePlan/Create.vue'),
    meta: {
      title: 'lang.cpq.business.label82', // 查看报价单
      breadcrumb: [
        { title: 'lang.cpq.menu.quotation.list', path: '/quotation' }, // 创建报价
        { title: 'lang.cpq.label.preQuote' }], // 预报价
    },
  },
  // 预报价规划报价单明细
  {
    path: 'pre-quotationReport/plan/view/:id',
    name: 'preQuotationReportItem',
    component: () => import('@/views/Quotation/PrePlan/Create.vue'),
    meta: {
      title: 'lang.cpq.business.label64',
      icon: 'calendar',
      breadcrumb: [
        { title: 'lang.cpq.menu.quotation.Report.list', path: '/quotationReport' }, // 创建报价
        { title: 'lang.cpq.quotation.label342' }, // 物料配置
      ],
    },
  },
];
