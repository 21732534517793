export default [
  {
    path: 'gross',
    name: 'gross',
    component: () => import('@/views/Base/Base'),
    meta: {
      title: 'lang.cpq.label.gross21',
      icon: 'help',
    },
    children: [
      // 毛利测算列表
      {
        path: 'gross-profit-calculation',
        name: 'lang.cpq.label.gross21',
        component: () => import('@/views/grossProfitCalculation/Index.vue'),
      },
      // 毛利测算列表
      {
        path: 'gross-profit-add',
        name: 'lang.cpq.label.gross20',
        component: () => import('@/views/grossProfitCalculation/Created.vue'),
      },
      {
        path: 'gross-profit-detail',
        name: 'lang.cpq.label.gross19',
        component: () => import('@/views/grossProfitCalculation/Index.vue'),
      },
    ],
  },
];
