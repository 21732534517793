<template>
  <div class="s-p-icon-info">
    <Tooltip :content="content"
             :max-width="maxWidth"
             :transfer="transfer"
             :placement="placement">
      <Icon :type="iconType" :style="{ color: iconColor }" />
      <template slot="content" v-if="isHasContentSlot">
        <slot name="content"></slot>
      </template>
    </Tooltip>
  </div>
</template>

<script>
export default {
  name: 'SPIconInfo',
  props: {
    content: String,
    iconType: {
      default: 'md-alert',
      type: String,
    },
    maxWidth: {
      default: 300,
      type: [Number, String],
    },
    placement: {
      default: 'bottom',
      type: String,
    },
    transfer: {
      default: false,
      type: Boolean,
    },
    iconColor: {
      type: String,
      default: '#409eff',
    },
  },
  computed: {
    isHasContentSlot() {
      return this.$slots.content && this.$slots.content.length;
    },
  },
};
</script>

<style lang="scss">
.s-p-icon-info{
  display: inline-block;
  .ivu-icon{
    font-size: 18px;
    color: $primary;
    cursor: pointer;
  }
  .ivu-tooltip-inner-with-width{
    min-width: 200px;
  }
}
</style>
