import zh from 'view-design/dist/locale/zh-CN';

const cn = {
  // 未登录之前不调用接口获取多语言数据
  'lang.cpq.username': '用户名',
  'lang.cpq.fe.请输入用户名': '请输入用户名',
  'lang.cpq.password': '密码',
  'lang.cpq.fe.请输入密码': '请输入密码',
  'lang.cpq.forgetPassword': '忘记密码',
  'lang.cpq.login': '登录',
  'lang.cpq.label.please.input.username': '登录账号不能为空',
  'lang.cpq.label.please.input.password': '密码不能为空',
  'lang.cpq.successfulLogin': '登录成功',
  'lang.cpq.message.error.network': '网络错误，请刷新重试或联系系统管理员',
  'lang.cpq.message.error.404': '系统部署升级中，请稍后再试',
  'lang.login.invalid.password': '账号或密码错误',

  // CPQ登录模块
  'lang.cpq.label.display.title': 'CPQ报价云平台',
  'lang.cpq.label.display.trail': '试算预报价',
  'lang.cpq.label.display.offer': '报价单管理',
  'lang.cpq.label.display.material': '物料维护',
  'lang.cpq.label.display.price': '价格配置',
  'lang.cpq.label.please.input.email': '请输入邮箱',
  'lang.cpq.button.back': '返回',
  'lang.cpq.button.submit': '提交',
  'lang.cpq.validator.required': '{name}不能为空',
  'lang.cpq.email': '邮箱',
  'lang.cpq.info.email.error': '请输入有效的邮箱地址',
  'lang.cpq.label.send.success': '发送成功',
  'lang.cpq.label.email.not.exist': '邮箱不存在',
  'lang.cpq.switch.lang.success': '语言切换成功',
  'lang.cpq.common.apiresult.InvalidAccess': '  无效的访问权限，请登录!',
  'lang.tip.login.no.access.right': '无权限访问此系统',
  'lang.cpq.common.apiresult.LockedUser': '此账号已被禁用，请联系管理员',

  'lang.cpq.menu.programReview': '方案评审',
  'lang.cpq.label.programReviewList': '方案评审列表',
  'lang.cpq.label.createProgramReview': '创建方案评审',
  'lang.cpq.label.programReviewDetail': '方案评审详情',
  'lang.cpq.label.programReviewNumber': '方案评审编号',
  'lang.cpq.label.programReviewName': '方案评审名称',
  'lang.cpq.label.businessType': '业务类型',
  'lang.cpq.label.salesStage': '销售阶段',
  'lang.cpq.label.change': '变更',
  'lang.cpq.label.whetherStandardProduct': '是否定制产品',
  'lang.cpq.label.associatedQuotation': '关联报价单',
  'lang.cpq.label.productManagerList': '产品经理名单',
  'lang.cpq.label.programInfo': '方案信息',
  'lang.cpq.label.configInfo': '配置信息',
  'lang.cpq.label.customType': '定制类型',
  'lang.cpq.label.whetherSoftwareCustom': '是否软件定制',
  'lang.cpq.label.projectScope': '项目范围',
  'lang.cpq.label.programReview.day1': '预估系统开发及实施人日（天）',
  'lang.cpq.label.programReview.day2': '预估机器人定制开发人日（天）',
  'lang.cpq.label.programReview.day3': '预计项目总工期（天）',
  'lang.cpq.label.technicalAgreement': '技术协议',
  'lang.cpq.label.programMeetingDiscussionResults': '方案会议讨论结果',
  'lang.cpq.label.programFeatures': '方案特点',
  'lang.cpq.label.sameMaterialSaveTip': '您选择了 {number} 个相同的物料，是否继续保存？',
  'lang.cpq.label.productSeries': '产品系列',
  'lang.cpq.label.productModel': '产品型号',
  'lang.cpq.label.productManager': '产品经理',
  'lang.cpq.label.productDirector': '产品总监',
  'lang.cpq.label.programReviewApproval': '方案评审审批',
  'lang.cpq.label.programReview.fillDescriptionTip': '填写说明：合同中约定的机器人型号、台数、软件功能、效率等。\n请保持与合同中规定一致！',
  'lang.cpq.label.productLineEmptyTip': '产品线不能为空',
  'lang.cpq.label.programReviewTemplate': '方案评审物料清单模板',
  'lang.cpq.label.opportunityChangeTip1': '商机的产品类型发生变化，从 {A} 变成 {B}，需要重新选择物料清单的产品线',
  'lang.cpq.label.opportunityChangeTip2': '商机的产品类型发生变化，从 {A} 变成 {B}，需要重新创建方案进行报价',
  'lang.cpq.label.belongDepartment': '所属部门',
  'lang.cpq.label.cpqProductType': 'CPQ产品类型',
  'lang.cpq.label.additionalQuotation': '增补报价单',
  'lang.cpq.label.additionalQuotationList': '增补报价单列表',
  'lang.cpq.label.createAdditionalQuotation': '创建增补报价单',
  'lang.cpq.label.serviceQuotation': '服务报价单',
  'lang.cpq.label.serviceQuotationList': '服务报价单列表',
  'lang.cpq.label.createServiceQuotation': '创建服务报价单',
  'lang.cpq.label.subtype': '子类型',
  'lang.cpq.label.service': '服务',
  'lang.cpq.label.additional': '增补',
  'lang.cpq.label.selectSales': '选择销售',
  'lang.cpq.label.warehousing': '仓储',
  'lang.cpq.label.normal': '普通',
  'lang.cpq.label.affiliateSalesQuotation': '关联销售报价单',
  'lang.cpq.label.notIncludeAllProductLine': '所选物料没有覆盖所有产品线',
  'lang.cpq.label.programConfigurationDetails': '方案配置明细',
  'lang.cpq.label.additionalRobotTip': '{name} 机器人/充电站不在主报价单里，不可以选择主报价单没有的机器人或者充电站',
  'lang.cpq.label.projectRemark': '项目备注',
  'lang.cpq.label.additionalProjectRemarkTip': '增补报价单的基本信息\n'
    + '项目名称：\n'
    + '项目地址：\n'
    + '项目描述信息：\n'
    + '其他：',
  'lang.cpq.label.serviceProjectRemarkTip': '服务报价单的基本信息\n'
    + '项目名称：\n'
    + '项目地址：\n'
    + '项目描述信息：\n'
    + '其他：',
  'lang.cpq.label.quoteSubTypeRequired': '子类型必填',
  'lang.cpq.label.return': '退回',
  'lang.cpq.label.returnConfirmTip': '确定退回当前审批单吗?',
  'lang.cpq.label.affiliateSalesQuotationRequired': '关联销售报价单必填',
  'lang.cpq.label.showOnlyTransferredOrdersTip': '只显示已转订单的销售报价单',
  'lang.cpq.label.materialPicture': '物料图片',
  'lang.cpq.label.cadDrawing': 'CAD图纸',
  'lang.cpq.label.projectNameServiceTip': '项目名称+售后服务类型（按次维修、按次维保、纯卖备件、延保服务、全保服务、驻场服务）+工单号（按次维修必填）',
  'lang.cpq.label.canNotAddRobotOrChargeTip': '不能添加机器人或者充电站物料',
  'lang.cpq.label.poc': 'POC',
  'lang.cpq.label.pocList': 'POC列表',
  'lang.cpq.label.pocApplicationName': 'POC申请名称',
  'lang.cpq.label.pocApplicationNumber': 'POC申请编号',
  'lang.cpq.label.createPocApplication': '创建POC申请',
  'lang.cpq.label.pocApplication': 'POC申请',
  'lang.cpq.label.pocStartDate': 'POC开始日期',
  'lang.cpq.label.pocEndDate': 'POC结束日期',
  'lang.cpq.label.pocResult': 'POC结果',
  'lang.cpq.label.terminate': '终止',
  'lang.cpq.label.feedbackResult': '反馈结果',
  'lang.cpq.label.confirmToTerminate': '是否确认终止？',
  'lang.cpq.label.confirmToReturn': '是否确认退回？',
  'lang.cpq.label.pocProgram': 'POC方案',
  'lang.cpq.label.customerProfile': '客户情况',
  'lang.cpq.label.customerExpectedRobotNumber': '客户预计订购机器人数量',
  'lang.cpq.label.industryReproducibilityAssessment': '行业可复制性评估',
  'lang.cpq.label.projectAddress': '项目地址',
  'lang.cpq.label.pocInformation': 'POC信息',
  'lang.cpq.label.implementationSubject': '实施主体',
  'lang.cpq.label.pocRequirementDescription': 'POC需求描述',
  'lang.cpq.label.applicationReason': '申请原因',
  'lang.cpq.label.whetherToCharge': '是否收费',
  'lang.cpq.label.feeAmount': '收费金额',
  'lang.cpq.label.feeDescription': '收费说明',
  'lang.cpq.label.whetherToSignAContract': '是否签署合同',
  'lang.cpq.label.pocOverview': 'POC概述',
  'lang.cpq.label.whetherNeedTestPerformance': '是否为性能类测试',
  'lang.cpq.label.whetherNeedPKWithCompetingProducts': '是否需要和竞品进行PK',
  'lang.cpq.label.whetherNeedCustomDevelopment': '是否需要定制开发',
  'lang.cpq.label.whetherNeedInterfacingWithTheCustomersSystem': '是否需要与客户系统对接联调',
  'lang.cpq.label.whetherNeedSystemImplementationPersonnelToBePresentForTesting': '是否需要系统实施人员到场测试',
  'lang.cpq.label.whetherNeedMaterialSupport': '是否需要物料支持',
  'lang.cpq.label.whetherNeedSystemDepartmentImplementationSupport': '是否需要系统部实施支持',
  'lang.cpq.label.whetherNeedServiceDepartmentImplementationSupport': '是否需要服务部实施支持',
  'lang.cpq.label.whetherNeedTheRobotDepartmentSupport': '是否需要机器人部支持',
  'lang.cpq.label.estimatedNumberOfMonthsOfUseMonths': '预计使用月数（月）',
  'lang.cpq.label.quotationCompany': '报价公司',
  'lang.cpq.label.whetherNeedElectronicSeal': '是否需要电子盖章',
  'lang.cpq.label.contractNumber': '合同编号',
  'lang.cpq.label.geekplusImplementation': '极智嘉实施',
  'lang.cpq.label.channelImplementation': '渠道实施（暂不支持）',
  'lang.cpq.label.fillInCustomerDemoRequirementsTip': '填写客户演示需求,包括具体的设备、物料及服务需求等',
  'lang.cpq.label.demoStartDateTip': '演示开始日期或设备借出日期',
  'lang.cpq.label.demoEndDateTip': '演示结束日期或设备归还日期',
  'lang.cpq.label.pocCalculationSheet': 'POC算单',
  'lang.cpq.label.whetherToRecycleAfterPoc': 'POC结束后是否回收',
  'lang.cpq.label.costPrice': '成本价格',
  'lang.cpq.label.totalMaterialCost': '物料成本总计',
  'lang.cpq.label.totalIndirectLaborCost': '间接人工成本总计',
  'lang.cpq.label.indirectLaborCost': '间接人工成本',
  'lang.cpq.label.otherCostRenovation': '其他费用-翻新',
  'lang.cpq.label.totalOtherIndirectCost': '其他间接成本总计',
  'lang.cpq.label.otherIndirectCost': '其他间接成本',
  'lang.cpq.label.otherCost': '其他费用',
  'lang.cpq.label.pocEstimatedTotalCost': 'POC预估总成本',
  'lang.cpq.label.costOption': '成本选项',
  'lang.cpq.label.pocManagement': 'POC管理',
  'lang.cpq.label.pocCalculationSheetCanNotBeNull': 'POC算单不能为空',
  'lang.cpq.label.costTypeRequire': '成本类型必填',
  'lang.cpq.label.costOptionRequire': '成本选项必填',
  'lang.cpq.label.costMaterialRequire': '成本物料销售名称必填',
  'lang.cpq.label.pocApproval': 'POC审批',
  'lang.cpq.label.whetherEquipmentRecycledAfterPocRecycled': 'POC结束后需要回收设备是否已回收',
  'lang.cpq.label.specificRecyclingDate': '具体回收日期',
  'lang.cpq.label.expectedRecyclingDate': '预计回收日期',
  'lang.cpq.label.pocActualEndDate': 'POC实际结束时间',
  'lang.cpq.label.successfulExperienceSharing': '成功经验分享',
  'lang.cpq.label.notImplementedReason': '未实施原因',
  'lang.cpq.label.rootReason': '根本原因',
  'lang.cpq.label.success': '成功',
  'lang.cpq.label.failed': '失败',
  'lang.cpq.label.notImplemented': '未实施',
  'lang.cpq.label.majorCategory': '大分类',
  'lang.cpq.label.causeCategory': '原因分类',
  'lang.cpq.label.causeDescription': '原因简述',
  'lang.cpq.label.pocResultMajorCategoryRequired': 'POC结果大分类必填',
  'lang.cpq.label.pocResultCauseCategoryRequired': 'POC结果原因分类必填',
  'lang.cpq.label.pocResultCauseDescriptionRequired': 'POC结果原因简述必填',
  'lang.cpq.label.pocResultCanNotBeNull': 'POC结果不能为空',
  'lang.cpq.label.indirectLaborCostCanNotBeNull': '间接人工成本不能为空',
  'lang.cpq.label.otherIndirectCostCanNotBeNull': '其他间接成本不能为空',
  'lang.cpq.label.pocNotEndTip': 'POC未到结束日期，确定需要结束填写结果吗？',
  'lang.cpq.label.pocProgramRequired': 'POC方案必填',
  'lang.cpq.label.pocFeeAmountTip': '收费金额在配置清单中修改',
  'lang.cpq.label.endTimeCannotLessThanStartTime': '结束时间不能小于开始时间',
  'lang.cpq.label.processRecord': '流程记录',
  'lang.cpq.label.materialCost': '物料成本',
  'lang.cpq.label.materialCostCanNotBeNull': '物料成本不能为空',
  'lang.cpq.label.reviewTask': '复盘任务',
  'lang.cpq.label.recoveryEquipmentList': '回收物料清单',
  'lang.cpq.label.handle': '处理',
  'lang.cpq.label.submitLinkErpTip': '注意：终版报价单同步到ERP的时候，是需要强制关联方案评审的，是否确认继续提交？',
  'lang.cpq.label.applicantName': '申请人姓名',
  'lang.cpq.label.handlerName': '处理人姓名',
  'lang.cpq.message.pocSyncErpInfo': '当前POC报价单已提交，并且已审批通过，是否立即同步报价单至ERP？',
  'lang.cpq.label.materialNoFixedCostTip': '该物料无固定成本，默认显示的是项目经理维护的成本作为销售价，可以修改',
  'lang.cpq.label.materialTag': '物料标签',
  'lang.cpq.label.materialNoFixedCostFillTip': '该物料无固定成本，请填写',
  'lang.cpq.label.confirmRecycleTime': '确认回收时间',
  'lang.cpq.label.regionBusinessOpportunity': '区域的商机',
  'lang.cpq.label.crossRegionalBusinessOpportunity': '跨区域负责的商机',
  'lang.cpq.label.businessOpportunityAnotherRegionTip': '该商机由其他区域的规划团队负责',
  'lang.cpq.label.createQuotation': '创建报价单',
  'lang.cpq.label.businessOpportunityOwner': '商机所有人',
  'lang.cpq.label.businessOpportunityDetail': '商机详情',
  'lang.cpq.label.notVisible': '不可见',
  'lang.cpq.label.costUnitPrice': '成本单价',
  'lang.cpq.label.totalCostPrice': '成本总价',
  'lang.cpq.label.viewProgramReview': '查看方案评审',
  'lang.cpq.label.endClientName': '最终客户名称',
  'lang.cpq.label.expectedSigningDate': '预计签约日期',
  'lang.cpq.label.salesOpportunityOwner': '销售机会所有人',
  'lang.cpq.label.phaseInformation': '阶段信息',
  'lang.cpq.label.channelPartner': '渠道伙伴',
  'lang.cpq.label.channelPartnerNumber': '渠道伙伴编号',
  'lang.cpq.label.channelPartnerName': '渠道伙伴名称',
  'lang.cpq.label.projectImplementationCity': '项目实施城市',
  'lang.cpq.label.opportunityType': '机会类型',
  'lang.cpq.label.projectImplementationProvince': '项目实施省份',
  'lang.cpq.label.projectImplementationCountryRegion': '项目实施国家/地区',
  'lang.cpq.label.expectedContractingQuarter': '预计签约季度',
  'lang.cpq.label.clientType': '客户类型',
  'lang.cpq.label.phaseUpdateTime': '阶段更新时间',
  'lang.cpq.label.latestActivityRecordTime': '最新活动记录时间',
  'lang.cpq.label.serviceType': '服务类型',
  'lang.cpq.label.departmentArea': '部门所属区域',
  'lang.cpq.label.sourceOfSalesOpportunity': '销售机会来源',
  'lang.cpq.label.ownerNumber': '所有人编号',
  'lang.cpq.label.associatedSalesOrder': '关联销售订单',
  'lang.cpq.label.expectedProjectGoLiveTime': '预计项目上线时间',
  'lang.cpq.label.customerArea': '客户领域',
  'lang.cpq.label.masterSalesOpportunity': '主销售机会',
  'lang.cpq.label.masterSalesOpportunityNumber': '主销售机会编号',
  'lang.cpq.label.advantagesCompetitorTechnologySolution': '竞争对手的技术/方案有哪些优势',
  'lang.cpq.label.3plEndCustomerName': '3PL的最终客户名称',
  'lang.cpq.label.3plEndCustomerIndustry': '3PL的最终客户行业',
  'lang.cpq.label.channelBusinessModel': '渠道业务模式',
  'lang.cpq.label.sourceOfCustomer': '客户来源',
  'lang.cpq.label.whetherCustomDevelopmentIsRequired': '是否需要定制化开发',
  'lang.cpq.label.planningEngineer': '规划工程师',
  'lang.cpq.label.lastRevisionDate': '最新修改日',
  'lang.cpq.label.RegionProjectImplementationCountryRegion': '项目实施国家/地区所属大区',
  'lang.cpq.label.businessTypeSecondary': '业务类型（二级）',
  'lang.cpq.label.distributor': '分销商',
  'lang.cpq.label.distributorCode': '分销商编码',
  'lang.cpq.label.distributorName': '分销商名称',
  'lang.cpq.label.businessTypeFirst': '业务类型（一级）',
  'lang.cpq.label.customerProvince': '客户省份',
  'lang.cpq.label.customerCountryRegion': '客户国家/地区',
  'lang.cpq.label.customerDecisionPlaceProvince': '客户决策地（省）',
  'lang.cpq.label.customerLevel': '客户等级',
  'lang.cpq.label.customerNumber': '客户编号',
  'lang.cpq.label.endCustomerAbbreviation': '最终客户简称',
  'lang.cpq.label.winningRate': '赢率',
  'lang.cpq.label.winOrderCommitment': '赢单承诺',
  'lang.cpq.label.loseOrderReason': '输单原因',
  'lang.cpq.label.loseOrderDescription': '输单描述',
  'lang.cpq.label.loseOrderReasonClass': '输单原因分类',
  'lang.cpq.label.loseOrderStage': '输单阶段',
  'lang.cpq.label.costMaintenance': '成本维护',
  'lang.cpq.label.lastModifiedDate': '最新修改日期',
  'lang.cpq.label.purchasedMaterialsApplication': '外购物料申请',
  'lang.cpq.label.whetherForSale': '是否用于销售',
  'lang.cpq.label.associatedVirtualMaterialNumber': '关联虚拟物料料号',
  'lang.cpq.label.bulkDelete': '批量删除',
  'lang.cpq.label.confirmDeleteSelectedMaterial': '是否确认删除所选物料？',
  'lang.cpq.label.planningManager': '规划负责人',
  'lang.cpq.label.editNoFixedCostPriceTip': '您所选择物料有无固定成本的物料，首次默认将填写的销售价作为成本，您也可以根据项目实际情况进行修改',
  'lang.cpq.label.viewPlanningQuote': '查看规划报价',
  'lang.cpq.label.planningSaleTeam': '规划销售团队',
  'lang.cpq.label.businessOpportunityPlanningTeamMembers': '商机规划团队成员',
  'lang.cpq.label.virtualMaterialNumber': '虚拟物料编号',
  'lang.cpq.label.applyOfficialMaterial': '申请正式物料',
  'lang.cpq.label.useDescriptionChinese': '用途描述(中文)',
  'lang.cpq.label.useDescriptionEnglish': '用途描述(英文)',
  'lang.cpq.label.remarkChinese': '备注(中文)',
  'lang.cpq.label.remarkEnglish': '备注(英文)',
  'lang.cpq.label.referenceCostPriceCnyExcludingTax': '参考成本（CNY不含税）',
  'lang.cpq.label.materialChinese': '材质(中文)',
  'lang.cpq.label.materialEnglish': '材质(英文)',
  'lang.cpq.label.netWeightKg': '净重(KG)',
  'lang.cpq.label.inquirer': '询价人',
  'lang.cpq.label.pleaseSelectInquirer': '请选择询价人',
  'lang.cpq.label.erpConfirm': 'ERP确认',
  'lang.cpq.label.erpConfirmTip': '这颗物料在ERP已完成采购价目表更新',
  'lang.cpq.label.confirm': '确认',
  'lang.cpq.label.applying': '申请中',
  'lang.cpq.label.noNeedSubmitTip': '初步接洽、确认商机阶段无需提交',
  'lang.cpq.label.fromVirtualMaterials': '来自虚拟物料',
  'lang.cpq.label.whetherSynchronizedErp': '是否同步ERP',
  'lang.cpq.label.virtualMaterialNotApprovedTip': '{num}行虚拟物料未审批完成，请检查后重新上传',
  'lang.cpq.label.officialMaterialNumberCorrespondingTip': '外购物料申请单对应的正式物料号',
  'lang.cpq.label.viewGrossProfitRange': '查看毛利区间',
  'lang.cpq.label.viewGrossProfitRangeTip': '当天查看次数达到{x}次后会通知主管；当前为第{y}次；',
  'lang.cpq.label.grossProfit': '毛利额',
  'lang.cpq.label.minimumDiscountRate': '最低折扣率（%）',
  'lang.cpq.label.lessThanMinimumDiscountRate': '低于最低销售折扣率',
  'lang.cpq.label.lessThanMinimumDiscountRateTip': '{name}物料已经低于最低销售折扣率了，是否继续？',
  'lang.cpq.label.targetGrossProfitRange': '目标毛利区间',
  'lang.cpq.label.standardGrossProfitRange': '标准毛利区间',
  'lang.cpq.label.inputFormalMaterialNumber': '填写正式料号',
  'lang.cpq.label.affiliateProgram': '关联方案',
  'lang.cpq.label.quotationNotAssociateProgramTip': '当前报价单未关联一个评审过的方案，请先选择一个评审过的方案',
  'lang.cpq.label.quotationNotPassProgramTip': '您选择的方案未通过物料的校验规则，请重新做方案或者报价单',
  'lang.cpq.label.materialVerificationRulesTip': '物料校验规则：\n'
    + '●  检查报价单的机器人物料） 和方案里面的物料复合按照大类（比如P800R)检查；并且数量的幅度范围（小于10台（+-50%），大于10台（+-20%））\n'
    + '●  非机器人的单一物料的金额占比>=5%，或者单价大于10万；这个物料必须在方案里存在；且数量幅度范围在（+-20%）之间',
  'lang.cpq.label.priceCannotBe0AtTheSameTime': '销售价格不能同时为0',
  'lang.cpq.label.pleaseAssociateAProgramFirst': '请先关联方案',
  'lang.cpq.label.contractNameAbbr': '合同名称简称',
  'lang.cpq.label.contractSignDate': '合同签署日期',
  'lang.cpq.label.contractFullName': '合同名称全写',
  'lang.cpq.label.endUser': '最终用户',
  'lang.cpq.label.contractOwner': '合同负责人',
  'lang.cpq.label.contractStartDate': '合同开始日期',
  'lang.cpq.label.contractEndDate': '合同结束日期',
  'lang.cpq.label.noContractTip': '请到CRM中创建合同，并把合同关联到客户，或联系CRM支持：代超（v-chao.dai@geekplus.com）',
  'lang.cpq.label.noProgramReviewTip': '该商机暂无评审过的方案，请先完成方案评审',
  'lang.cpq.label.createPreQuoteConfigurationManifest': '创建预报价配置清单',
  'lang.cpq.label.whetherPreQuote': '是否预报价',
  'lang.cpq.label.cloneOfficialQuote': '克隆正式报价',
  'lang.cpq.label.createPreQuote': '创建预报价',
  'lang.cpq.label.invoiceApplication': '开票申请',
  'lang.cpq.label.invoiceList': '开票列表',
  'lang.cpq.label.createInvoiceApplication': '发起开票申请',
  'lang.cpq.label.createInvoice': '发起开票',
  'lang.cpq.label.applicationType': '申请类型',
  'lang.cpq.label.orderNumber': '订单号',
  'lang.cpq.label.applicationAmount': '申请金额',
  'lang.cpq.label.applicationDate': '申请日期',
  'lang.cpq.label.processProgress': '流程进度',
  'lang.cpq.label.purchaseUnitInformation': '购货单位信息',
  'lang.cpq.label.invoiceContent': '开票内容',
  'lang.cpq.label.mailInformation': '邮寄信息',
  'lang.cpq.label.applicantDepartment': '申请人部门',
  'lang.cpq.label.applicationProjectNumber': '申请项目编号',
  'lang.cpq.label.applicationProjectName': '申请项目名称',
  'lang.cpq.label.contractNature': '合同性质',
  'lang.cpq.label.invoiceSubject': '开票主体',
  'lang.cpq.label.organizationCode': '组织编码',
  'lang.cpq.label.invoiceCategory': '发票类别',
  'lang.cpq.label.unitName': '单位名称',
  'lang.cpq.label.taxpayerIdentificationNumber': '纳税人识别号',
  'lang.cpq.label.address': '地址',
  'lang.cpq.label.depositBank': '开户行',
  'lang.cpq.label.bankAccount': '银行账户',
  'lang.cpq.label.manuallyAddItems': '手动添加物料',
  'lang.cpq.label.selectMaterialFromOrder': '从订单中选择物料',
  'lang.cpq.label.belongOrder': '所属订单',
  'lang.cpq.label.unitPriceIncludeTax': '单价含税金额',
  'lang.cpq.label.totalPriceIncludeTax': '合计含税金额',
  'lang.cpq.label.cny': '元',
  'lang.cpq.label.invoiceRemark': '发票备注',
  'lang.cpq.label.whetherNeedShip': '是否需要邮寄',
  'lang.cpq.label.shipMethod': '寄件方式',
  'lang.cpq.label.recipient': '收件人',
  'lang.cpq.label.shipAddress': '收件地址',
  'lang.cpq.label.writeOffApplication': '冲销申请',
  'lang.cpq.label.writeOffList': '冲销列表',
  'lang.cpq.label.writeOffInformation': '冲销信息',
  'lang.cpq.label.relatedInvoiceRequestForm': '关联开票申请单',
  'lang.cpq.label.originalInvoiceInvoiceCode': '原发票发票代码',
  'lang.cpq.label.originalInvoiceInvoiceNumber': '原发票发票号码',
  'lang.cpq.label.whetherCertified': '是否认证',
  'lang.cpq.label.redInvoiceInformationFormNumber': '红字发票信息表编号',
  'lang.cpq.label.clickToView': '点击前往查看',
  'lang.cpq.label.order': '订单',
  'lang.cpq.label.xxxRequired': '{name}必填',
  'lang.cpq.label.xxxCanNotBeNull': '{name}不能为空',
  'lang.cpq.label.grandTotalPriceIncludeTax': '总计含税金额',
  'lang.cpq.label.projectNumber': '项目编号',
  'lang.cpq.label.contractClient': '签约客户',
  'lang.cpq.label.affiliatedProject': '所属项目',
  'lang.cpq.label.returnedAmount': '已回款金额',
  'lang.cpq.label.plannedReturnAmount': '计划回款金额',
  'lang.cpq.label.invoicedAmount': '已开票金额',
  'lang.cpq.label.applicationStatus': '申请状态',
  'lang.cpq.label.invoiceOrder': '开票订单',
  'lang.cpq.label.poNumber': 'PO编号',
  'lang.cpq.label.vatNumber': 'VAT编号',
  'lang.cpq.label.country': '国家',
  'lang.cpq.label.customerContactPerson': '客户联系人',
  'lang.cpq.label.customerFinancialContactPerson': '客户财务联系人',
  'lang.cpq.label.phoneNumber': '电话号码',
  'lang.cpq.label.geek+Information': '极智嘉信息',
  'lang.cpq.label.contactPersonInformation': '联系人信息',
  'lang.cpq.label.title': '标题',
  'lang.cpq.label.goodsSales': '货物销售',
  'lang.cpq.label.goodsImportCountry': '货物进口国',
  'lang.cpq.label.goodsDeliveryDate': '货物交付日期',
  'lang.cpq.label.goodsDeliveryAddress': '货物交付地址',
  'lang.cpq.label.paymentTerm': '支付条款',
  'lang.cpq.label.paymentTermAndInvoiceAmountToBeIssued': '支付条款和要开具的发票金额',
  'lang.cpq.label.paymentSchedule': '付款日程',
  'lang.cpq.label.paymentPercentage': '付款占比',
  'lang.cpq.label.paymentAmount': '付款金额',
  'lang.cpq.label.invoiceNeedToBeIssuedOrNot': '是否需要开具发票',
  'lang.cpq.label.invoiceAmountToBeIssuedAtThisTime': '此时要开出的发票金额',
  'lang.cpq.label.supportingDocumentNeedToBeProvided': '需要提供证明文件',
  'lang.cpq.label.whetherContractAgreedExchangeRate': '合同是否约定汇率',
  'lang.cpq.label.unitPrice': '单价金额',
  'lang.cpq.label.totalPrice': '合计金额',
  'lang.cpq.label.grandTotalPrice': '总计金额',
  'lang.cpq.label.writeOffInvoiceNumber': '冲销发票号',
  'lang.cpq.label.writeOffReason': '冲销原因',
  'lang.cpq.label.invoice': '开票',
  'lang.cpq.label.writeOff': '冲销',
  'lang.cpq.label.invoiceApproval': '开票审批',
  'lang.cpq.label.applicationTime': '申请时间',
  'lang.cpq.label.serviceSales': '服务销售',
  'lang.cpq.label.serviceProvisionPeriod': '服务提供周期',
  'lang.cpq.label.serviceDeliveryAddress': '服务交付地址',
  'lang.cpq.label.goodsInstallationAddress': '货物安装地址',
  'lang.cpq.label.invoiceType': '开票类型',
  'lang.cpq.label.createWriteOff': '发起冲销申请',
  'lang.cpq.label.backToPrevious': '返回上一级',
  'lang.cpq.label.downloadInvoice': '下载发票',
  'lang.cpq.label.overseaInvoiceFileTip': '对于从一个欧盟国家进口的货物将最终发往另一个欧盟国家，应在Geekplus方面取得以下支持性证据并妥善存档。\n'
    + '• 客户的订单（包括客户的姓名、增值税号和货物的交货地址）和销售合同\n'
    + '• 包装清单\n'
    + '• 负责将货物运出进口国的承运人的商业运输文件\n'
    + '• 作为付款证明的银行对账单\n'
    + '如果客户是美国转售商，请提供客户的转售证明',
  'lang.cpq.label.applicationSn': '申请单号',
  'lang.cpq.label.invoiceResult': '开票结果',
  'lang.cpq.label.overseaTotalPriceTip': '单价金额 * 数量 * 支付条款付款比例',
  'lang.cpq.label.noPrevious': '无上一级',
  'lang.cpq.label.invoiceSubjectArea': '开票主体区域',
  'lang.cpq.label.writeOffProcessNumber': '冲销流程编号',
  'lang.cpq.label.hongKong': '香港',
  'lang.cpq.label.domestic': '国内',
  'lang.cpq.label.overseas': '海外',
  'lang.cpq.label.taxAmount': '税额',
  'lang.cpq.label.overPlannedReturnAmountTip': '订单的开票总金额超过计划回款金额',
  'lang.cpq.label.syncTaxCategory': '同步税收分类',
  'lang.cpq.label.taxCategory': '税收分类',
  'lang.cpq.label.contract': '合同',

  'lang.gpm.fe.label.orderLogQuery': '订单日志查询',
  'lang.gpm.fe.label.enterOrderNo': '请输入完整订单编号，不支持模糊查询',
  'lang.gpm.fe.label.orderQuoteInfo': '订单/报价单信息',
  'lang.gpm.fe.label.order': '订单',
  'lang.gpm.fe.label.quote': '报价单',
  'lang.gpm.fe.label.businessmodel': '业务模式',
  'lang.gpm.fe.label.quoteType': '报价类型',
  'lang.gpm.fe.label.quoteSubType': '报价子类型',
  'lang.gpm.fe.label.syncToCpqTime': '同步到CPQ时间',
  'lang.gpm.fe.label.close': '关闭',
  'lang.gpm.fe.label.OrderNumber': '订单编号',
  'lang.gpm.fe.label.operationLog': '操作日志',
  'lang.gpm.fe.label.operationTime': '操作时间',
  'lang.gpm.fe.label.orderNotExistTip': '查询的订单不存在，请输入完整的订单查询。如果是当天的新订单，请先联系PMO到“订单分配”菜单刷新ERP订单，如果还是查询不到，请去ERP系统查找订单是否存在',
  'lang.cpq.label.importHomemadeMaterialsTargetCostData': '导入自制物料目标成本数据',
  'lang.cpq.label.exportHomemadeMaterialsTargetCostTemplateAndData': '导出自制物料目标成本模板和数据',
  'lang.cpq.label.importHomemadeMaterialsTargetCostDataTip': '导入前请先下载“导出自制物料目标成本模板和数据”，并保持模板格式一致',
  'lang.cpq.label.invoicedQuantity': '已开票数量',
  'lang.cpq.label.invoiceableQuantity': '可开票数量',
  'lang.cpq.label.currentRole': '当前角色',
  'lang.cpq.label.configurableRole': '可配置角色',
  'lang.cpq.label.signatureTip': '任签人员以/隔开，其余为会签',
  'lang.cpq.label.pendingApprover': '待审批人',
  'lang.cpq.label.noMaterialTip': 'CPQ当前无相关物料，请登录ERP或联系ERP支持：邵帅（v-shuai.shao@geekplus.com）查看是否同步物料到CPQ了',
  'lang.cpq.label.preQuoteButtonTip': '1. 预报价单不能作为对外报价依据，且不支持下载、不能同步ERP\n'
    + '2. 未进入投标报价环节的商机可以发起预报价，且审批流与正式报价不同\n'
    + '3. 当商机进入投标报价环节后，可复制预报价单为正式报价单，并重新按照正式报价的流程进行审批',
  'lang.cpq.label.preQuote': '预报价',
  'lang.cpq.label.preQuoteConfig': '预报价配置',
  'lang.cpq.label.pricePendingSearch': '定价待办查询',
  'lang.cpq.label.planningQuoteTip': 'CRM商机中添加规划人员后，请联系销售触发实时商机同步，或待第2天系统自动从CRM同步',
  'lang.cpq.label.integratedMaterial': '套装物料',
  'lang.cpq.label.createIntegratedMaterial': '创建套装物料',
  'lang.cpq.label.exportIntegratedMaterialList': '导出套装物料清单',
  'lang.cpq.label.downloadIntegratedMaterialListTemplate': '下载套装物料清单导入模板',
  'lang.cpq.label.integratedMaterialCode': '套装物料编码',
  'lang.cpq.label.createMaterialCategory': '创建物料分类',
  'lang.cpq.label.integratedMaterialTemplate': '套装物料导入模板',
  'lang.cpq.label.integratedMaterialDisabledTip': '物料已禁用',
  'lang.cpq.label.integratedMaterialDiscontinuedTip': '物料已停售',
  'lang.cpq.label.integratedMaterialSaveErrorTip': '子物料存在禁用/停售的物料',
  'lang.cpq.label.systemsRequiringCustomization': '需要定制的系统',
  'lang.cpq.label.systemName': '系统名称',
  'lang.cpq.label.customizedContentDescription': '定制内容的描述',
  'lang.cpq.label.whetherHighAvailability': '是否适用高可用配置',
  'lang.cpq.label.highAvailabilityNumber': '需要配置高可用的服务器台数',
  'lang.cpq.label.highAvailabilityDescription': '高可用说明',
  'lang.cpq.label.efficiencyTargetsRequiredByCustomers': '客户要求效率指标',
  'lang.cpq.label.solutionMeetEfficiencyTargets': '当前方案可满足效率指标',
  'lang.cpq.label.simulationMeasurementFile': '仿真测算文件',
  'lang.cpq.label.exportIntegratedMaterialListBasedOnTemplate': '根据模板导出套装物料清单',
  'lang.cpq.label.exportIntegratedMaterialTip': '请先下载套装物料清单的导入模板，然后根据模板数据导出套装物料的详细信息',
  'lang.cpq.label.hardwareCustomDevelopmentManDays': '硬件定制开发人天',
  'lang.cpq.label.r&DMaterialCost': '研发物料成本',
  'lang.cpq.label.softwareCustomDevelopmentManDays': '软件定制开发人天',
  'lang.cpq.label.materialNumberConversion': '料号转换',
  'lang.cpq.label.materialNumberConversionTip': '1. 导入文件的来自Gplan，如果模板有变更请提需求给数字化团队\n'
    + '2. 也可以下载支持的模板格式，手动转换后上传\n'
    + '3. 当前支持的产线：拣选(P)',
  'lang.cpq.label.viewIntegratedMaterialInfo': '查看套装物料信息',
  'lang.cpq.label.enterIntegratedMaterialCodeTip': '请输入完整的套装物料编码查询',
  'lang.cpq.label.noIntegratedMaterialInfo': '查询的套装物料不存在，请输入完整的套装物料编码查询',
  'lang.cpq.label.clone': '克隆',
  'lang.cpq.label.transferInquiry': '转派询价',
  'lang.cpq.label.customProgram': '定制方案',
  'lang.cpq.label.customProgramTip': '如涉及硬件或系统定制，系统将根据产品经理填写的信息自动在配置清单中生成相应物料信息，请勿重复添加涉及系统或硬件的定制物料',
  'lang.cpq.label.integratedMaterialName': '套装物料名称',
  'lang.cpq.label.transferProgress': '转正进度',
  'lang.cpq.label.isRobot': '是否机器人',
  'lang.cpq.label.discontinuedRegion': '停售大区',
  'lang.cpq.fe.label.3PLEndCustomerIndustry': '3PL最终客户行业',
  'lang.cpq.fe.label.3PLEndCustomerName': '3PL最终客户名称',
  'lang.cpq.label.contractType': '合同类型',
  'lang.cpq.label.contractName': '合同名称',
  'lang.cpq.label.whetherIntegratedMaterial': '是否是集成物料',
  'lang.cpq.label.transfer': '转派',
  'lang.cpq.label.zeroRemarkTip': '数量为0的物料备注必填',
  'lang.cpq.label.priceRemarkTip': '金额为0的物料备注必填',
  'lang.cpq.label.subMaterielCode': '子物料编码',
  'lang.cpq.label.cooperativePartner': '合作伙伴',
  'lang.cpq.label.purchasedMaterialsApproval': '外购物料审批',
  'lang.cpq.label.required': '必填',
  'lang.cpq.label.whetherDiscontinued': '是否停售',
  'lang.cpq.label.whetherDisabled': '是否禁用',
  'lang.cpq.label.confirmCreate': '确认创建',
  'lang.cpq.label.confirmCreatePreQuote': '确认创建预报价吗？',
  'lang.cpq.label.serviceBusinessOpportunityType': '服务商机类型',
  'lang.cpq.label.serviceProductCategory': '服务产品类别',
  'lang.cpq.label.recommendedMaterial': '推荐物料',
  'lang.cpq.label.replaceMaterialCode': '替代物料编码',
  'lang.cpq.label.replaceMaterialInformation': '替代物料信息',
  'lang.cpq.fe.label.invoiceApplicationSearchTip': '如果需要冲销已审批完成的开票申请，在当前月可以发起[作废申请]，非当前月可以发起[冲销申请]',
  'lang.cpq.label.gross01': '毛利测算名称',
  'lang.cpq.label.gross02': '创建毛利测算',
  'lang.cpq.label.gross03': '编辑毛利测算',
  'lang.cpq.label.gross04': '编辑毛利编号',
  'lang.cpq.label.gross05': '签约币种',
  'lang.cpq.label.gross06': '产品线金额占比（%）',
  'lang.cpq.label.gross07': '产品线金额',
  'lang.cpq.label.gross08': '机器人版本',
  'lang.cpq.label.gross09': '场地面积（㎡）',
  'lang.cpq.label.gross10': '成本（单价）',
  'lang.cpq.label.gross11': '销售（单价）',
  'lang.cpq.label.gross12': '报价项目',
  'lang.cpq.label.gross13': '销售总计',
  'lang.cpq.label.gross14': '合约币种',
  'lang.cpq.label.gross15': '销售总价 - 港元（不含税)',
  'lang.cpq.label.gross16': '项目物料',
  'lang.cpq.label.gross17': '实施人工',
  'lang.cpq.label.gross18': '其他(关税)',
  'lang.cpq.label.gross19': '查看毛利测算',
  'lang.cpq.label.gross23': '不含税',
  'lang.cpq.label.gross24': '修改商机会清空当前物料，是否继续？',
  'lang.cpq.label.gross25': '毛利测算编号',
  'lang.cpq.label.gross34': '销售总价不得大于999999999999',
  'lang.cpq.label.gross43': '硬件定制内容',
  'lang.cpq.label.gross44': '硬件定制内容必填',
  'lang.cpq.label.gross45': '至少有一个物料数量不为0',
  loading: '系统正在加载中......',
  ...zh,
};

export default cn;
