import Vue from 'vue';
import VueI18n from 'vue-i18n';
import Cookies from 'js-cookie';
import cn from './langs/cn';
import en from './langs/en';

Vue.use(VueI18n);
// Vue.locale = () => {};

export const languages = { cn: '中文', en: 'English' };

const messages = { en, cn };

export const getLanguage = () => {
  const chooseLanguage = Cookies.get('language');
  if (chooseLanguage) return chooseLanguage;

  const language = (navigator.language || navigator.browserLanguage).toLowerCase();
  const locales = Object.keys(messages);
  for (let i = 0; i < locales.length; i += 1) {
    const item = locales[i];
    if (language.indexOf(item) > -1) {
      return item;
    }
  }
  return 'en';
};

// ZH_CN | ZH_HK | EN_US | JA_JP
export const getLanguageInfo = (lang = null) => {
  const newLang = lang || getLanguage();
  return {
    lang: newLang,
    fullName: {
      cn: 'ZH_CN', en: 'EN_US', hk: 'ZH_HK', ja: 'JA_JP',
    }[newLang],
  };
};

const lang = getLanguage();
const i18n = new VueI18n({ locale: lang, messages, silentTranslationWarn: true });

export const setLanguage = (language) => {
  i18n.locale = language;
  Cookies.set('language', language);
};
export const setLangPack = (language, message) => {
  if (messages[language]) {
    i18n.setLocaleMessage(language, { ...messages[language], ...message });
  }
};
export default i18n;
