<template>
  <div class="s-p-search-box2-wrap">
    <div class="s-p-search-box2" :style="boxStyle">
      <Row style="flex: 1" ref="node" :gutter="12">
        <slot></slot>
      </Row>
      <div class="search-btns" v-if="isShowBtn">
        <el-button size="small" @click="handleReset" :loading="loading" plain>{{ $t(resetTxt) }}</el-button>
        <el-button size="small" @click="handleSearch" :loading="loading" type="primary">{{ $t(searchTxt) }}</el-button>
        <a v-if="allSpanNum > 24"
                type="text"
                :style="{width: lang === 'en' ? '80px' : '60px'}"
                class="switch-btn"
                @click="switchOpen">
          <span style="margin-right: 4px">{{ langMap[isOpen ? 'sp.label.searchBox.collapse' : 'sp.label.searchBox.expand'] }}</span>
          <i v-if="isOpen" class="el-icon-arrow-up"></i>
          <i v-else class="el-icon-arrow-down"></i>
        </a>
      </div>
    </div>
  </div>

</template>

<script>
import './style.scss';
import i18nMixin from '../utils/i18nMixin';

export default {
  name: 'SearchBox2',
  mixins: [i18nMixin],
  provide() {
    return {
      enterClick: this.enterClick,
    };
  },
  data() {
    return {
      isOpen: false,
      allSpanNum: 0,
    };
  },
  props: {
    searchTxt: {
      type: String,
      default: 'lang.cpq.button.search',
    },
    resetTxt: {
      type: String,
      default: 'lang.cpq.button.reset',
    },
    isShowBtn: {
      type: Boolean,
      default: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    enterClick: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    switchOpen() {
      this.isOpen = !this.isOpen;
    },
    handleReset() {
      this.$emit('on-reset');
    },
    handleSearch() {
      if (this.loading) {
        return;
      }
      this.$emit('on-search');
    },
  },
  computed: {
    boxStyle() {
      let height = '80px';
      if (this.isOpen || !this.isShowBtn) {
        height = `${Math.ceil(this.allSpanNum / 24) * 80}px`;
      }
      return {
        height,
      };
    },
  },
  mounted() {
    if (this.$refs.node.$children.length) {
      this.$refs.node.$children.forEach((item) => {
        this.allSpanNum += item.colSpan;
      });
    }
  },
};
</script>
