<template>
  <div class="s-p-edit-cell">
    <div class="s-p-edit-cell__value" :style="{paddingRight: isEdit ? '50px' : '25px'}">
      <div class="s-p-edit-cell__value__input" v-if="isEdit">
        <Input type="textarea" v-if="type === 'textarea'"
               v-model="newValue"
               show-word-limit
               :maxlength="maxlength"
               :placeholder="placeholder"></Input>
        <s-p-format-input v-model="newValue"
                          change-type="blur"
                          v-else-if="isFormatInput"
                          :type="type"
                          :format="format"
                          :placeholder="placeholder">
          <span v-if="appendStr" slot="append">{{appendStr}}</span>
        </s-p-format-input>
        <Input v-model="newValue"
               v-else
               :placeholder="placeholder">
          <span v-if="appendStr" slot="append">{{appendStr}}</span>
        </Input>
      </div>
      <template v-else>
        <template v-if="$slots.content">
          <slot name="content"></slot>
        </template>
        <template v-else>
          <Tooltip placement="top-start" transfer max-width="200" v-if="isToolTip">
            <div slot="content">
              <span v-if="isFormatNumber">
                <span v-if="isDynamic">{{ value | formatNumberDynamic }}</span>
                <span v-else>{{ value | formatNumber }}</span>
              </span>
              <span v-else>{{ value }}</span>
            </div>
            <div class="s-p-edit-cell__value__show"
                 v-if="isFormatNumber">
              <span v-if="isDynamic">{{ value | formatNumberDynamic(formatNumberResult) }}<span v-if="appendStr">{{appendStr}}</span></span>
              <span v-else>{{ value | formatNumber(formatNumberResult) }}<span v-if="appendStr">{{appendStr}}</span></span>
            </div>
            <div class="s-p-edit-cell__value__show" v-else>{{ value }}<span v-if="appendStr">{{appendStr}}</span></div>
          </Tooltip>
          <template v-else>
            <div class="s-p-edit-cell__value__show"
                 v-if="isFormatNumber">
              <span v-if="isDynamic">{{ value | formatNumberDynamic(formatNumberResult) }}<span v-if="appendStr">{{appendStr}}</span></span>
              <span v-else>{{ value | formatNumber(formatNumberResult) }}<span v-if="appendStr">{{appendStr}}</span></span>
            </div>
            <div class="s-p-edit-cell__value__show" v-else>{{ value }}<span v-if="appendStr">{{appendStr}}</span></div>
          </template>
        </template>
      </template>
    </div>
    <div class="s-p-edit-cell__action" v-if="!disabled">
      <Icon v-if="!isEdit"
            :size="size"
            @click="isEdit = !isEdit"
            type="md-create"
            class="s-p-edit-cell__action__edit" />
      <template v-else>
        <Icon :size="size"
              type="md-checkmark"
              @click="handleValueChange"
              class="s-p-edit-cell__action__save" />
        <Icon :size="size"
              type="md-close"
              @click="handleValueCancel"
              class="s-p-edit-cell__action__cancel" />
      </template>
    </div>
  </div>
</template>

<script>
import Emitter from 'view-design/src/mixins/emitter';

export default {
  name: 'SPEditCell',
  mixins: [Emitter],
  props: {
    format: {
      type: String,
      default: 'positiveInteger',
    },
    placeholder: {
      type: String,
      default: '',
    },
    value: {
      required: true,
      default: '',
    },
    size: {
      type: Number,
      default: 20,
    },
    type: {
      type: String,
      default: 'text',
    },
    maxlength: {
      type: Number,
      default: 200,
    },
    isFormatNumber: Boolean,
    formatNumberResult: String,
    isFormatInput: {
      default: true,
      type: Boolean,
    },
    disabled: Boolean,
    appendStr: String,
    isToolTip: {
      default: true,
      type: Boolean,
    },
    // 是否动态显示小数点位数
    isDynamic: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      isEdit: 0,
      newValue: '',
    };
  },
  watch: {
    value: {
      handler(val) {
        this.newValue = val;
      },
      immediate: true,
    },
  },
  methods: {
    handleValueChange() {
      this.$emit('input', this.newValue);
      this.$emit('on-change', this.newValue);
      this.dispatch('FormItem', 'on-form-change', this.newValue);
      this.isEdit = !this.isEdit;
    },
    handleValueCancel() {
      this.newValue = this.value;
      this.isEdit = !this.isEdit;
    },
  },
};
</script>

<style lang="scss">
  .s-p-edit-cell{
    position: relative;
    &__value{
      &__show{
        padding: 0 4px;
        height: 32px;
        line-height: 32px;
        word-break: break-all;
      }
    }
    &__action{
      height: 32px;
      padding: 6px 5px;
      width: 50px;
      position: absolute;
      right: 0;
      top: 0;
      text-align: right;
      .ivu-icon{
        cursor: pointer;
        color: #999;
        font-weight: bold;
        vertical-align: top;
        &:hover{
          color: #333;
        }
      }
      &__save.ivu-icon{
        color: #19be6b;
        &:hover{
          color: #47cb89;
        }
      }
      &__cancel.ivu-icon{
        color: #ed4014;
        &:hover{
          color: #f16643;
        }
      }
    }
  }
</style>
