export default [
  {
    path: 'config',
    name: 'config',
    component: () => import('@/views/Base/Base'),
    meta: {
      title: 'lang.cpq.menu.configuration',
      icon: 'config',
    },
    children: [
      // 数据字典
      {
        path: 'dictionary',
        name: 'configDictionary',
        component: () => import('@/views/Config/Dictionary/List.vue'),
        meta: {
          title: 'lang.cpq.menu.config.data.dictionary',
          breadcrumb: [{ title: 'lang.cpq.menu.configuration', path: '/config/dictionary' }, { title: 'lang.cpq.menu.config.data.dictionary' }],
        },
      },
      // 系统参数
      {
        path: 'system',
        name: 'configSystem',
        component: () => import('@/views/Config/System/List.vue'),
        meta: {
          title: 'lang.cpq.menu.configuration.parameterMaintenance',
          breadcrumb: [{ title: 'lang.cpq.menu.configuration', path: '/config/system' }, { title: 'lang.cpq.menu.config.system.parameter' }],
        },
      },
      // 拣选试算参数
      {
        path: 'trial-param-picking',
        name: 'trialParamPicking',
        component: () => import('@/views/Config/Params/Picking.vue'),
        meta: {
          title: 'lang.cpq.menu.config.trialParamPicking',
          breadcrumb: [{ title: 'lang.cpq.menu.configuration', path: '/config/trial-param-picking' }, { title: 'lang.cpq.menu.config.trialParamPicking' }],
        },
      },
      // 搬运试算参数
      {
        path: 'trial-param-moving',
        name: 'trialParamMoving',
        component: () => import('@/views/Config/Params/Moving.vue'),
        meta: {
          title: 'lang.cpq.menu.config.trialParamMoving',
          breadcrumb: [{ title: 'lang.cpq.menu.configuration', path: '/config/trial-param-moving' }, { title: 'lang.cpq.menu.config.trialParamMoving' }],
        },
      },
      // 搬运试算参数
      {
        path: 'trial-param-forklift',
        name: 'trialParamForklift',
        component: () => import('@/views/Config/Params/Forklift.vue'),
        meta: {
          title: 'lang.cpq.menu.config.trialParamForklift',
          breadcrumb: [{ title: 'lang.cpq.menu.configuration', path: '/config/trial-param-forklift' }, { title: 'lang.cpq.menu.config.trialParamForklift' }],
        },
      },
      // 运费模块
      {
        path: 'shipping',
        name: 'lang.cpq.menu.config.shipping',
        component: () => import('@/views/Config/Shipping/List.vue'),
        meta: {
          title: 'lang.cpq.menu.config.shipping',
          icon: 'shipping',
          breadcrumb: [{ title: 'lang.cpq.menu.configuration', path: '/config/shipping' }, { title: 'lang.cpq.menu.config.shipping' }],
        },
      },
      // 邮件发送频率
      {
        path: 'email',
        name: 'lang.cpq.menu.config.email',
        component: () => import('@/views/Config/Email/Notice.vue'),
        meta: {
          title: 'lang.cpq.menu.config.email',
          icon: 'statistics',
          breadcrumb: [{ title: 'lang.cpq.menu.configuration', path: '/config/email' }, { title: 'lang.cpq.menu.config.email' }],
        },
      },
      // 审批待办定时提醒
      {
        path: 'todo-reminder',
        name: 'lang.cpq.menu.config.todo.reminder',
        component: () => import('@/views/Config/TodoReminder/Index.vue'),
        meta: {
          title: 'lang.cpq.menu.config.email',
          icon: 'statistics',
          breadcrumb: [{ title: 'lang.cpq.menu.configuration', path: '/config/email' }, { title: 'lang.cpq.menu.config.email' }],
        },
      },
    ],
  },
];
