export default [
  {
    path: 'team',
    name: 'team',
    component: () => import('@/views/Team/List'),
    meta: {
      title: 'lang.cpq.config.label9',
      icon: 'team',
    },
  },
];
