import store from '@/store';

export default {
  has(key) {
    return store.state.user.buttonData
      .findIndex((item) => item.name === key && item.showFlag === true) !== -1;
  },
  can(key) {
    if (!this.has(key)) {
      //  alert
    }
  },
};
