<template>
  <div class="s-p-no-data">
    <div class="s-p-no-data__img" v-if="imgSrc">
      <img :src="imgSrc" alt="No Data">
    </div>
    <div class="s-p-no-data__icon" v-if="iconClass">
      <svg-icon :icon-class="iconClass" :style="{fontSize: iconSize}" />
    </div>
    <div class="s-p-no-data__title" v-if="title">
      {{ title }}
    </div>
    <div class="s-p-no-data__content">
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SPNoData',
  props: {
    imgSrc: String,
    iconClass: {
      type: String,
      default: 'noData',
    },
    iconSize: {
      type: String,
      default: '60px',
    },
    title: {
      type: String,
      default: '暂无数据',
    },
  },
};
</script>

<style lang="scss">
  .s-p-no-data{
    text-align: center;
    padding-top: 100px;
    &__img{
      margin-bottom: 20px;
    }
    &__icon{
      margin-bottom: 20px;
    }
    &__title{
      margin-bottom: 20px;
      font-size: 18px;
    }
    &__content{
      margin-bottom: 20px;
      font-size: 14px;
    }
  }
</style>
