import axios from 'axios';
import Vue from 'vue';
import store from '@/store/index';
import {Message} from 'view-design';
import i18n, {getLanguage} from '@/i18n/i18n';
import router from "@/router/index";
import {downloadFile, paramsStringify} from '@/utils/util';
import {specialCodeList} from '@/config/enum.config'; // 特殊的code，需要有root处理

const REQUEST_TIMEOUT = 360; // 请求超时时间：秒
const passCode = [0, 406];
let loginTimeout = false;
const {CancelToken} = axios;
const globalDuration = 5; // 报错延时：秒
const loginPath = window.location.hostname === 'localhost' ? '/login-up' : '/login';

// 向服务器发送请求返回的状态
const validateStatus = (status) => status >= 200 && status < 300;
// 对response 数据进行处理
const httpResponse = (res, root = false, fileType, message) => {
  const {
    duration = globalDuration, closable = false, show = true,
  } = message;
  const {headers} = res;
  const contentDisposition = headers['content-disposition'];
  if (typeof contentDisposition !== 'undefined') {
    const fileName = contentDisposition && contentDisposition.split(';')[1].replace("fileName=", '');
    downloadFile(res.data, fileType, decodeURIComponent(fileName));
    return true;
  } else {
    const {
      data,
      code,
      msg,
    } = res.data;
    if (root) {
      if (show && specialCodeList.includes(code)) {
        Message.error({
          content: i18n.t(msg || 'lang.cpq.message.error.network'),
          duration,
          closable,
        });
      }
      // 如果能够通过code验证，则代表成功
      if (passCode.includes(code) || specialCodeList.includes(code)) {
        return res.data;
      }

    }
    if (code === 2) {
      store.dispatch('user/resetToken').then(() => {
        router.push(loginPath)
      })
      Message.error({
        content: i18n.t('lang.hestia.message.error.login.timeout'),
        duration
      });
      return false;
    }
    if (code === 407) {
      if (loginTimeout) {
        return false;
      }
      loginTimeout = true;
      Message.error({
        content: i18n.t(msg || 'lang.cpq.message.error.network'),
        duration,
        closable,
      });
      router.push(loginPath);
      // setTimeout(() => location.reload(), 500);
      return false;
    }
    if (code === 500) {
      Message.error({
        content: i18n.t(msg || 'lang.cpq.message.error.network'),
        duration,
        closable,
      });
      if (store && store.dispatch('user/resetToken')) {
        store.dispatch('user/resetToken').then(() => {
          router.push(loginPath);
        }, error => {
          router.push(loginPath);
        })
      } else {
        router.push(loginPath);
      }
      throw new Error(JSON.stringify(res));
    }
    if (code === 3) {
      router.push('/403');
      return false;
    }
    if (code === 100) {
      if (data && data.sipErrorMessage) {
        Message.error(data.sipErrorMessage);
      } else {
        Message.error({
          content: i18n.t(msg || 'lang.cpq.message.error.network'),
          duration,
          closable,
        });
      }
      // Promise.reject(res);
      // return false;
      throw new Error(JSON.stringify(res));
    }
    if (passCode.includes(code) || res.config.url.endsWith('wmstoolbox/download')) {
      return data ?? {};
    }

    Message.error({
      content: i18n.t(msg || 'lang.cpq.message.error.network'),
      duration,
      closable,
    });
    throw new Error(JSON.stringify(res));
  }
};

/**
 * Requests a URL, return a promise.
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "axios"
 * @return {object}           An object containing either "data" or "err"
 */
export default function request(url, options = {}) {
  const {
    method = 'get',
    headers = {},
    params = {},
    body = {},
    events = {},
    isGlobal = false,
    responseType,
    fileType = 'octet-stream',
    root = false,
    message = {
      duration: 5,
      closable: false,
      show: true,
    }
  } = options;
  for (let key in params) {
    if (typeof params[key] === 'string') {
      params[key] = params[key].trim();
    }
  }
  const {
    onUploadProgress = () => {
    },
    onDownloadProgress = () => {
    },
  } = events;
  let requestHeaders = {
    'Content-type': 'application/json; charset=UTF-8',
    language: getLanguage(),
    CPQKey: sessionStorage.getItem('cpq_token'),
    ...headers,
  };
  // 判断传入的参数是否是formData
  if (Object.prototype.toString.call(body) === '[object FormData]') {
    requestHeaders = {
      language: getLanguage(),
      CPQKey: sessionStorage.getItem('cpq_token'),
      ...headers,
    };
  }

  return axios({
    method,
    url,
    params,
    data: body,
    timeout: REQUEST_TIMEOUT * 1000,
    headers: requestHeaders,
    baseURL: process.env.VUE_APP_BASE_API,
    cancelToken: new CancelToken((c) => {
      if (!isGlobal) {
        Vue.prototype.cancelList.push(c);
      }
    }),
    responseType,
    // 上传进度事件
    onUploadProgress,
    // 下载进度事件
    onDownloadProgress,
    validateStatus,
    withCredentials: true,
  }).then((response) => httpResponse(response, root, fileType, message))
    .catch((error) => {
      // 如果是手动取消的请求，不需要报错
      if (error.toString() !== 'Cancel') {
        if (url.indexOf('/api/exceptionLog/save') > -1) {
          return;
        }
        try {
          // code 为100，500和其他未定义是的报错收集
          collectFeException(JSON.parse(error.message), url, method, params, body);
        } catch (e) {
          // 请求已发出，但服务器响应的状态码不在 2xx 范围内
          if (error.response) {
            collectFeException(error.response, url, method, params, body);
          }
          // 网络错误
          console.error('Error', error.message);
          Message.error(i18n.t('lang.cpq.message.error.404'));
        }
      }
    });
}

const collectFeException = (response, url, method, params, body) => {
  const {
    status, statusText,
  } = response;
  const {
    appVersion, cookieEnabled, deviceMemory,
    language, onLine, platform, userAgent,
  } = navigator;
  const {
    employeeId, username, roles,
  } = store.state.user;
  if (status !== 404 && employeeId && username) {
    request('/api/exceptionLog/save', {
      method: 'post',
      body: {
        type: 'fe.request.exception',
        className: '',
        method,
        errorCode: status,
        errorParam: JSON.stringify({
          params,
          body: Object.prototype.toString.call(body) === '[object FormData]' ? '文件上传' : body,
        }),
        requestServlet: url,
        errorTrace: JSON.stringify({
          status,
          statusText,
          url,
          employeeId,
          username,
          roles,
          href: location.href,
          clientDate: new Date().getTime(),
          appVersion, cookieEnabled, deviceMemory,
          language, onLine, platform, userAgent,
        })
      }
    })
  }
}
