import enLocale from 'element-ui/lib/locale/lang/en';
import en from 'view-design/dist/locale/en-US';

const cn = {
  'lang.cpq.switchLang': 'Switch Language Success',

  'lang.cpq.map.system.wms': 'WMS',
  'lang.cpq.map.system.rms': 'RMS',
  'lang.cpq.map.system.ips': 'IPS',
  'lang.cpq.map.product.wh': 'WAREHOUSE',
  'lang.cpq.map.product.so': 'SELECT',
  'lang.cpq.map.approve.p': 'PEDDING',
  'lang.cpq.map.approve.a': 'APPROVED',
  'lang.cpq.map.approve.s': 'SAVED',
  'lang.cpq.map.approve.r': 'REJECTED',

  'lang.cpq.dashboard': 'Dash Board Page',

  'lang.cpq.username': 'Username',
  'lang.cpq.fe.请输入用户名': 'Please input username',
  'lang.cpq.password': 'Password',
  'lang.cpq.fe.请输入密码': 'Please input password',
  'lang.cpq.login': 'Login',
  'lang.cpq.logout': 'Logout',
  'lang.cpq.successfulLogin': 'Login Success',
  'lang.cpq.forgetPassword': 'Forget password',
  'lang.cpq.label.please.input.email': 'Please input email',
  'lang.cpq.button.back': 'Back',
  'lang.cpq.button.submit': 'Submit',
  'lang.cpq.email': 'Mailbox',
  'lang.cpq.info.email.error': 'userEmail is not a valid email',
  'lang.cpq.label.please.input.username': 'Login account cannot be empty',
  'lang.cpq.label.please.input.password': 'Password cannot be empty',
  'lang.cpq.tip.login.password': 'Incorrect username or password',

  'lang.cpq.param.tag': 'Tags',
  'lang.cpq.param.param': 'Param',
  'lang.cpq.param.name': 'Name',
  'lang.cpq.param.node': 'Node',
  'lang.cpq.param.group': 'Group',
  'lang.cpq.param.format': 'Param Format',
  'lang.cpq.param.value': 'Value',
  'lang.cpq.param.defaultValue': 'Default',
  'lang.cpq.param.longText': 'Description',
  'lang.cpq.param.image': 'Effect image',
  'lang.cpq.param.viewImage': 'View effect image',
  'lang.cpq.param.noImageAvailable': 'No Image Available for this param',
  'lang.cpq.param.imageDesc': 'Image description',
  'lang.cpq.param.relevantParam': 'Relevant Param',
  'lang.cpq.param.hot': 'Hot',
  'lang.cpq.param.sequence': 'Sequence',
  'lang.cpq.param.system': 'System',
  'lang.cpq.param.productLine': 'Product Line',
  'lang.cpq.param.owner': 'Owner',
  'lang.cpq.param.reason': 'Add Reason',
  'lang.cpq.param.fromVersion': 'From Version',
  'lang.cpq.param.activeStatus': 'Active Status',
  'lang.cpq.param.mandatory': 'Mandatory',
  'lang.cpq.param.developer': 'Developer',
  'lang.cpq.param.tester': 'Tester',
  'lang.cpq.param.checker': 'Approver',
  'lang.cpq.param.createdDate': 'Create Date',
  'lang.cpq.param.valueDesc': 'description',
  'lang.cpq.param.status': 'Status',
  'lang.cpq.param.valueAtLeastTwo': 'value requires two items at least',
  'lang.cpq.param.formatSequence': 'Sequence No',

  'lang.cpq.menu.home': 'Home',
  'lang.cpq.menu.config': 'Param Config',
  'lang.cpq.menu.wms': 'WMS',
  'lang.cpq.menu.steps': 'Steps',
  'lang.cpq.menu.search': 'Param Search',
  'lang.cpq.menu.manage': 'Param Manage',
  'lang.cpq.menu.apply': 'Param Apply',
  'lang.cpq.menu.approve': 'Param Approve',
  'lang.cpq.menu.projectParamSearch': 'Project Param Search',
  'lang.cpq.menu.newProject': 'New Project Param',
  'lang.cpq.menu.newparam': 'New Param List',
  'lang.cpq.menu.collect': 'Param Collections',
  'lang.cpq.menu.healthCheck': 'Health Check',
  'lang.cpq.menu.healthCheckAdd': 'Add Health Check Item',
  'lang.cpq.menu.tools': 'Tools Support',
  'lang.cpq.menu.toolsShelf': 'Tools - Shelf',
  'lang.cpq.menu.toolsRobot': 'Tools - Robot',
  'lang.cpq.menu.toolsWorkflow': 'Tools - Workflow',
  'lang.cpq.menu.toolsWms': 'Tools - WMS',
  'lang.cpq.menu.logic': 'Logic Adjust',
  'lang.cpq.menu.shelf': 'Shelf Info Adjust',
  'lang.cpq.menu.workflow': 'Workflow',
  'lang.cpq.menu.customizeWorkflow': 'Customize Workflow',
  'lang.cpq.menu.workflow.add': 'Add Workflow',
  'lang.cpq.menu.implement': 'Implement Tools',
  'lang.cpq.menu.robotmonitor': 'Robot Monitor',
  'lang.cpq.menu.robotcontrol': 'Robot Control',
  'lang.cpq.menu.project': 'Project Manage',
  'lang.cpq.menu.project.list': 'Project List',
  'lang.cpq.menu.project.new': 'New Project',
  'lang.cpq.menu.project.edit': 'Edit Project',
  'lang.cpq.menu.shelfConsistencyCheck': 'Shelf Consistency Check',
  'lang.cpq.menu.paramedit': 'Param Edit',
  'lang.cpq.menu.account': 'Account',
  'lang.cpq.menu.accountUser': 'Account User',
  'lang.cpq.menu.accountRole': 'Account Role',
  'lang.cpq.menu.monitor': 'Monitor',
  'lang.cpq.menu.dashboard': 'Dashboard',
  'lang.cpq.menu.generateApproveToken': 'Generate Approve Token',
  'lang.cpq.menu.projectConfig': 'Project Configuration',

  'lang.cpq.search.title': 'Search Param',
  'lang.cpq.search.allSystem': 'All System',
  'lang.cpq.search.placeholder': 'please input param name, product line or developer name...',
  'lang.cpq.search.tip': 'You can search by param name, node name, describle, owner, developer and so on...',
  'lang.cpq.search.noresult': 'no param matched!',

  'lang.cpq.apply.system': 'System',
  'lang.cpq.apply.product': 'Product Line',
  'lang.cpq.apply.status': 'Approve Status',
  'lang.cpq.apply.applyParam': 'Apply Param',
  'lang.cpq.apply.applyForm': 'Apply Form',
  'lang.cpq.apply.submitSuccess': 'Param Submit Success',

  'lang.cpq.approve.approveParam': 'Approve Param',
  'lang.cpq.approve.approve': 'Approve',
  'lang.cpq.approve.placeholder': 'Approve Result & Reason',
  'lang.cpq.approve.pass': 'Pass',
  'lang.cpq.approve.reject': 'Reject',
  'lang.cpq.approve.rejectNotNull': 'Reject reason is required',
  'lang.cpq.approve.passSuccess': 'Approve Success',
  'lang.cpq.approve.rejectSuccess': 'Approve Rejected',

  'lang.cpq.param.title': 'Param Info',
  'lang.cpq.param.edit': 'Param Edit',
  'lang.cpq.param.applyForm': 'Apply Form',
  'lang.cpq.param.commentArea': 'Comment Area',
  'lang.cpq.param.commentPlaceholder': 'Feed back here, when troubles block you',
  'lang.cpq.param.commentSuccess': 'Comment Success',

  'lang.cpq.param.button.confirm': 'Confirm',
  'lang.cpq.param.button.submit': 'Submit',
  'lang.cpq.param.button.search': 'Search',
  'lang.cpq.param.button.apply': 'Apply',
  'lang.cpq.param.button.cancel': 'Cancel',

  'lang.cpq.wmsTool.button.download': 'download',
  'lang.cpq.wmsTool.button.upload': 'upload',

  'lang.cpq.validator.required': '{name} is required',

  'lang.btc.common.util.apiresult.exceptionOrError': 'api exception',

  'lang.cpq.common.apiresult.InvalidAccess': 'Invalid access, please login!',
  'lang.cpq.error.apiresult.UnauthorizedAccess': 'Unauthorized access, please login!',
  'lang.cpq.common.apiresult.LockedUser': 'Account is locked, please contact admin',
  'lang.cpq.update.password': 'Update password',
  'lang.cpq.update.passwordNotIdentical': 'two times new password not the same',
  'lang.cpq.update.passwordUpdateSuccess': 'Password update successfully',
  'lang.cpq.update.uncorrectOldPassword': 'Old Password is not correct',
  'lang.cpq.update.oldPassword': 'Old Password',
  'lang.cpq.update.newPassword': 'New Password',
  'lang.cpq.update.newPasswordConfirm': 'New Password Confirm',
  'lang.cpq.update.newPasswordMandatory': 'New password is mandatory',
  'lang.cpq.update.newPasswordConfirmMandatory': 'New password confirm is mandatory',
  'lang.cpq.update.oldPasswordMandatory': 'Old password is mandatory',
  'lang.cpq.update.firstLogin': 'Password is never changed, please update your password.',
  'lang.cpq.button.search': 'Search',
  'lang.cpq.button.reset': 'Reset',
  'lang.cpq.button.new': 'Add',
  'lang.cpq.button.import': 'Import',
  'lang.cpq.button.edit': 'Edit',
  'lang.cpq.button.save': 'Save',
  'lang.cpq.button.delete': 'Delete',
  'lang.cpq.label.operation': 'Operation',
  'lang.cpq.input.placeholder': 'Please Input',

  'lang.cpq.spare.reciptient1': 'Reciptient 1',
  'lang.cpq.spare.parts.request.info': 'Spare Parts Request Info',
  'lang.cpq.spare.contact.detail1': 'Contact detail 1',
  'lang.cpq.spare.reciptient2': 'Reciptient 2',
  'lang.cpq.spare.contact.detail2': 'Contact detail2',
  'lang.cpq.spare.pic': 'PIC',
  'lang.cpq.spare.creator': 'Creator',
  'lang.cpq.spare.created.time': 'Created Time',
  'lang.cpq.spare.parts.list.info': 'Spare Parts List Info',
  'lang.cpq.spare.part.des': 'Spare part Des',
  'lang.cpq.spare.part.no': 'Spare part No',
  'lang.cpq.spare.recipient': 'Recipient',
  'lang.cpq.spare.contact.detail': 'Contact detail',
  'lang.cpq.spare.no': 'NO.',
  'lang.cpq.spare.status': 'Status',
  'lang.cpq.spare.parts.info': 'Spare Part Info',
  'lang.cpq.spare.spare.part.des': 'Spare part des',

  // component
  'lang.cpq.label.component.name': 'Name',
  'lang.cpq.label.component.type': 'Type',
  'lang.cpq.label.component.materielNo': 'Materiel Number',
  'lang.cpq.label.component.specModel': 'Specification Model',
  'lang.cpq.label.component.fitType': 'Applicable Model',
  'lang.cpq.label.component.remark': 'Remark',
  'lang.cpq.label.return.date': 'Return date',
  'lang.cpq.label.maintenance.engineer': 'Maintenance engineer',
  'lang.cpq.label.faultDate': 'faultDate',

  // equipment
  'lang.cpq.menu.project.equiment': 'equipment list',
  'lang.cpq.menu.equipment.list': 'equipment list',
  'lang.cpq.menu.equipment.detail': 'equipment detail',
  'lang.cpq.label.equipment.sn': 'SN',
  'lang.cpq.label.project.relative': 'Related Project',
  'lang.cpq.label.eqiuipment.cpu': 'CPU',
  'lang.cpq.label.eqiuipment.ram': 'RAM',
  'lang.cpq.label.eqiuipment.hardDisk': 'Hard Disk',
  'lang.cpq.label.eqiuipment.graphicsCard': 'Graphics Card',
  'lang.cpq.label.eqiuipment.masterVer': 'Master Version',
  'lang.cpq.label.eqiuipment.robotMasterBootVer': 'Robot Master Boot Version',
  'lang.cpq.label.eqiuipment.robotMasterCfgVer': 'Robot mMster Configuration File Program Version',
  'lang.cpq.label.eqiuipment.imageSoftwareVer': 'ZYNQ/DSP Image Program Version',
  'lang.cpq.label.eqiuipment.cpldVer': 'CPLD Version',
  'lang.cpq.label.eqiuipment.driverSoftwareVer': 'Driver version',
  'lang.cpq.label.eqiuipment.industrialComputerSoftwareVer': 'Industrial Computer (M series) / Upper Computer System Software Version',
  'lang.cpq.label.eqiuipment.acoustoOpticModuleSoftwareVer': 'Acousto-optic Module Driver Version',
  'lang.cpq.label.eqiuipment.obstacleAvoidanceBoxSoftwareVer': 'Obstacle Avoidance Program Version',
  'lang.cpq.label.eqiuipment.powerBoxSoftwareVer': 'Power Box Program Version',
  'lang.cpq.label.eqiuipment.armSoftwareVer': 'ARM (M series) Program Version',
  'lang.cpq.label.eqiuipment.displaySoftwareVer': 'Charging Station Display Program Version',
  'lang.cpq.label.eqiuipment.programUpgradeRemark': 'Program Upgrade Notes',
  'lang.cpq.label.eqiuipment.batterySoftwareVer': 'Battery Program Version',
  'lang.OA.login.failed.employee.not.exist': 'Not able to Authenticate the user, please contact SMP admin to add the user',
  'lang.login.invalid.password': 'Invalid user name or password',
  'lang.OA.login.timeout': 'OA Login time out',
  'lang.OA.login.failed.invalid.param': 'Invalid OA Parameters',
  'lang.OA.login.failed': 'SSO Login failed',
  'lang.OA.login.invalid.token': 'OA Authentication failed, please login manually',
  'lang.cpq.menu.ticket.audit.edit': 'Ticket Edit',

  'lang.cpq.message.error.network': 'Network error, please refresh and try again or contact system administrator',
  'lang.cpq.message.error.404': 'System deployment and upgrade, please try again later',

  // 系统模块
  'lang.hestia.system.module0': 'All Modules',
  'lang.hestia.system.module1': 'Implement',
  'lang.hestia.system.module2': 'Project Info',
  'lang.hestia.system.module3': 'Param',
  'lang.hestia.system.module4': 'License',
  'lang.hestia.system.module5': 'MAC',
  'lang.hestia.system.module6': 'Admin',
  'lang.hestia.system.module7': 'Migration',
  'lang.hestia.system.module8': 'Monitor',
  'lang.hestia.system.module9': 'Ticket',
  'lang.hestia.system.module10': 'Equipment',

  // CPQ登录模块
  'lang.cpq.label.display.title': 'CPQ Quotation Cloud Platform',
  'lang.cpq.label.display.trail': 'Trial pre-quote',
  'lang.cpq.label.display.offer': 'Quotation Management',
  'lang.cpq.label.display.material': 'Material Maintenance',
  'lang.cpq.label.display.price': 'Price Allocation',
  'lang.cpq.label.email.not.exist': 'Email address not exist',
  'lang.cpq.label.send.success': 'Send successfully',
  'lang.cpq.switch.lang.success': 'Language switch successfully',
  'lang.tip.login.no.access.right': 'No permission to access this system',

  'lang.gpm.fe.label.orderLogQuery': 'Order Log Query',
  'lang.gpm.fe.label.enterOrderNo': 'Please enter a complete order number. Fuzzy query is not supported',
  'lang.gpm.fe.label.orderQuoteInfo': 'Order/Quote Information',
  'lang.gpm.fe.label.order': 'Order',
  'lang.gpm.fe.label.quote': 'Quote',
  'lang.gpm.fe.label.businessmodel': 'Business Model',
  'lang.gpm.fe.label.quoteType': 'Quote type',
  'lang.gpm.fe.label.quoteSubType': 'Quote subtype',
  'lang.gpm.fe.label.syncToCpqTime': 'ERP synchronization to CPQ time',
  'lang.gpm.fe.label.close': 'Close',
  'lang.gpm.fe.label.OrderNumber': 'Order Number',
  'lang.gpm.fe.label.operationLog': 'Operation log',
  'lang.gpm.fe.label.operationTime': 'Operation time',
  'lang.gpm.fe.label.orderNotExistTip': 'The order queried does not exist. Please enter a complete order query. If it is a new order of the current day, please contact PMO to refresh the ERP order in the "Order Allocation" menu. If it is still not found, please go to the ERP system to find out whether the order exists',
  'lang.cpq.label.required': 'Required',
  'lang.cpq.label.gross01': 'Gross profit calculation name',
  'lang.cpq.label.gross02': 'Create gross profit calculation',
  'lang.cpq.label.gross03': 'Edit gross profit calculation',
  'lang.cpq.label.gross04': 'Edit gross profit number',
  'lang.cpq.label.gross05': 'Contract currency',
  'lang.cpq.label.gross06': 'Proportion of product line amount (%)',
  'lang.cpq.label.gross07': 'Product line amount',
  'lang.cpq.label.gross08': 'Robot version',
  'lang.cpq.label.gross09': 'Venue area (㎡)',
  'lang.cpq.label.gross10': 'Cost (unit price)',
  'lang.cpq.label.gross11': 'Sales (unit price)',
  'lang.cpq.label.gross12': 'Quotation project',
  'lang.cpq.label.gross13': 'Total sales',
  'lang.cpq.label.gross14': 'Contract Currency',
  'lang.cpq.label.gross15': 'Total sales price - HKD (excluding tax)',
  'lang.cpq.label.gross16': 'Project Materials',
  'lang.cpq.label.gross17': 'Implement manual labor',
  'lang.cpq.label.gross18': 'Other (tariff)',
  'lang.cpq.label.gross19': 'View gross profit calculation',
  'lang.cpq.label.gross23': 'excluding tax',
  'lang.cpq.label.gross24': 'Modifying the business opportunity will clear the current material. Do you want to continue?',
  'lang.cpq.label.gross25': 'Gross profit calculation number',
  'lang.cpq.label.gross34': 'The total sales price shall not exceed 99999999999',
  'lang.cpq.label.gross43': 'Hardware customization content',
  'lang.cpq.label.gross44': 'Hardware customization content is required',
  'lang.cpq.label.gross45': 'At least one material quantity is not 0',
  loading: 'System is loading......',
  ...enLocale,
  ...en,
};

export default cn;
