import {
  Message,
} from 'view-design';
import i18n from '@/i18n/i18n';
import {
  login,
  logout,
  getMenuByLogin,
  getLoginUser,
  getMenuBySystemModule,
} from '@/service/account/user'; // 引入x
import {
  getCookie,
  setCookie,
} from '@/utils/cookie';

const financeRoleList = ['JPBP', 'RaaSBP', 'CNBP', 'APBP', 'USBP', 'EUBP', 'financial_director'];

export default {
  namespaced: true,
  state: {
    userId: getCookie('USERID'),
    username: getCookie('USERNAME'),
    regionId: getCookie('REGIONID'),
    menuData: [],
    buttonData: [],
    roles: [],
    allRoles: [],
    priceList: [],
    systemModule: sessionStorage.getItem('systemModule') || 'ALL',
    defaultPath: '/home',
    jobTitleEn: 'China',
    employeeId: '',
    isRegionAdmin: false, // roles[0] === 'REGION_admin'
    isAdmin: false,
    isFinance: false,
    areaCode: '',
    isSale: false,
    isSaleOperation: false,
    isPlanner: false,
    isProjectManager: false,
    isPriceManager: false, // 是否是价格管理员
    isProduct: false, // 产品
    plannerFlag: false, // 是否是规划负责人
    regionFinancialTMFlag: false, // 是否是税务经理
    gpmPmoFlag: false,
    gpmProjectBpFlag: false,
    deptNameCn: '',
    deptNameEn: '',
    userInfo: {},
  },
  mutations: {
    // 更新登录信息
    UPDATE_LOGIN(state, user) {
      state.userId = user.id;
      state.username = user.userName;
      state.regionId = user.regionId;
      state.createTicketFlag = user.createTicketFlag;
      state.serviceL1Flag = user.serviceL1Flag;
      state.userCategory = user.userCategory;
      state.jobTitleEn = user.jobTitleEn;
      state.employeeId = user.employeeId;
      state.priceList = user.priceList ? user.priceList.split(',') : [];
      state.plannerFlag = user.plannerFlag;
      state.regionFinancialTMFlag = user.regionFinancialTMFlag;
      state.gpmPmoFlag = user.gpmPmoFlag;
      state.gpmProjectBpFlag = user.gpmProjectBpFlag;
      state.deptNameCn = user.deptNameCn;
      state.deptNameEn = user.deptNameEn;
      state.userInfo = user;
    },
    UPDATE_LOADING(state, payLoad) {
      const keys = Object.keys(payLoad);
      keys.map((item) => {
        state[item] = payLoad[item];
        return true;
      });
    },
    SET_ROLE(state, roles) {
      state.roles = roles;
    },
    SET_ALL_ROLES(state, roles) {
      state.allRoles = roles;
    },
    SET_MENU(state, menuData) {
      state.menuData = menuData;
    },
    SET_BUTTON(state, buttonData) {
      state.buttonData = buttonData;
    },
    SET_SYSTEM_MODULE(state, systemModule) {
      state.systemModule = systemModule;
    },
    updateState(state, payload) {
      Object.assign(state, payload);
    },
  },
  actions: {
    // 登录接口
    async login({ commit }, payLoad) {
      const res = await login(payLoad);
      if (res) {
        const {
          id, userName, regionId,
        } = res;
        commit('UPDATE_LOGIN', res);
        setCookie('USERID', id);
        setCookie('USERNAME', userName);
        setCookie('REGIONID', regionId);
        return true;
      }
      return false;
    },
    async initMenu({
      commit,
      state,
    }) {
      let res = false;
      if (state.systemModule) {
        res = await getMenuBySystemModule({
          roleId: null,
          systemModule: state.systemModule === 'ALL' ? null : state.systemModule,
        });
      } else {
        res = await getMenuByLogin({
          roleId: null,
        });
      }
      if (res && res.code === 0) {
        commit('updateState', {
          menuData: res.data.menus,
          buttonData: res.data.buttons,
          defaultPath: res.defaultPath,
        });
        return true;
      }
      commit('updateState', {
        menuData: [],
        buttonData: [],
        defaultPath: '',
      });
      return false;
    },
    async initUser({
      commit,
    }, fromLogin = false) {
      const res = await getLoginUser();
      if (res) {
        if (fromLogin && res.firstLogin) {
          Message({
            message: i18n.t('lang.hestia.update.firstLogin'),
            type: 'info',
          });
        }
        commit('UPDATE_LOGIN', res);
        setCookie('USERID', res.id);
        setCookie('USERNAME', res.userName);
        commit('SET_ROLE', res.systemRoleList.filter((role) => role.currentLoginRole));
        commit('SET_ALL_ROLES', res.systemRoleList);
        commit('updateState', {
          isRegionAdmin: res.systemRoleList
            .some((role) => role.roleCode === 'REGION_admin' && role.id === res.lastLoginRoleId),
          isAdmin: res.systemRoleList
            .some((role) => ['CPQ_admin', 'admin'].includes(role.roleCode) && role.id === res.lastLoginRoleId),
          isFinance: res.systemRoleList
            .some((role) => financeRoleList.includes(role.roleCode) && role.id === res.lastLoginRoleId),
          isSale: res.systemRoleList
            .some((role) => role.roleCode === 'SALE' && role.id === res.lastLoginRoleId),
          isSaleOperation: res.systemRoleList
            .some((role) => role.roleCode === 'sales_operation' && role.id === res.lastLoginRoleId),
          isPlanner: res.systemRoleList
            .some((role) => role.roleCode === 'ChinaPlanner' && role.id === res.lastLoginRoleId),
          areaCode: res.areaCode,
          isPriceManager: res.systemRoleList
            .some((role) => role.roleCode === 'PriceManager' && role.id === res.lastLoginRoleId),
          isProjectManager: res.systemRoleList
            .some((role) => role.roleCode === 'project_mgr' && role.id === res.lastLoginRoleId),
          isProduct: res.systemRoleList
            .some((role) => role.roleCode === 'product' && role.id === res.lastLoginRoleId),
        });
      }
    },
    setMenu({
      commit,
    }, menuData, buttonData) {
      commit('SET_MENU', menuData);
      commit('SET_BUTTON', buttonData);
    },
    // 登出接口
    async logout({
      commit,
    }) {
      const res = await logout();
      if (res) {
        commit('UPDATE_LOGIN', {});
        commit('SET_MENU', []);
        commit('SET_BUTTON', []);
        commit('updateState', {
          systemModule: 'ALL',
        });
        sessionStorage.setItem('systemModule', '');
        return true;
      }
      return false;
    },
  },
};
