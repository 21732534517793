<template>
  <div class="s-p-import-file">
    <Upload ref="upload" :show-upload-list="false" :disabled="disabled || loading" :on-success="handleSuccess" :format="format" :accept="accept" :max-size="maxSize * 1024" :before-upload="handleBeforeUpload" with-credentials multiple action="/"
      class="s-p-import-file__main">
      <Button :loading="loading" :disabled="disabled" :icon="icon" :type="btnType">{{label}}</Button>
    </Upload>
  </div>
</template>

<script>
import request from '@/utils/requset';
import i18n from '@/i18n/i18n';

export default {
  name: 'SPImportFile',
  props: {
    label: {
      type: String,
      default: i18n.t('lang.muse.button.import'),
    },
    url: {
      type: String,
      default: '/api/ticket/document/addDocument',
      required: true,
    },
    format: {
      type: Array,
      default() {
        return [];
      },
    },
    maxSize: {
      type: Number,
      default: 20,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    accept: {
      type: String,
      default: '',
    },
    icon: String,
    btnType: {
      type: String,
      default: 'default',
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    uploadFn() {
      return (options) => request(this.url, options);
    },
    upFormat() {
      if (this.format.length) {
        return this.format.map((item) => item.toUpperCase());
      }
      return [];
    },
  },
  methods: {
    handleSuccess() {
      this.$emit('on-success');
    },
    handleBeforeUpload(file) {
      const isFormat = this.handleFormat(file);
      if (isFormat) {
        const isMaxSize = this.handleMaxSize(file);
        if (isMaxSize) {
          this.upload(file);
        }
      }
      return false;
    },
    async upload(file) {
      const formData = new FormData();
      formData.append('file', file);
      this.loading = true;
      const res = await this.uploadFn({
        method: 'post',
        body: formData,
      });
      if (res) {
        this.loading = false;
        this.handleSuccess();
      }
    },
    getSuffix(name) {
      if (name) {
        const nameSplit = name.split('.');
        return nameSplit[nameSplit.length - 1];
      }
      return '';
    },
    handleFormat(file) {
      const suffix = this.getSuffix(file.name);
      if (suffix) {
        const upSuffix = suffix.toUpperCase();
        if (this.upFormat.length && !this.upFormat.includes(upSuffix)) {
          this.$Message.error({
            content: `The format of the file named ${file.name} should be one of ${JSON.stringify(this.format)}`,
            duration: 10,
          });
          return false;
        }
      }
      return true;
    },
    handleMaxSize(file) {
      if (file.size > this.maxSize * 1024 * 1024) {
        this.$Message.error({
          content: `The size of the file named ${file.name} should be less than ${this.maxSize}MB`,
          duration: 10,
        });
        return false;
      }
      return true;
    },
  },
};
</script>

<style lang="scss">
.s-p-import-file {
  display: inline-block;
}
</style>
