import request from '@/utils/requset';

export const getI18nByLanguage = (lang) => request('api/language/getI18nByLanguage', {
  params: {
    language: lang,
  },
});

export const getCodeTable = (data) => request('/api/codetable/getCodeTable', {
  method: 'post',
  body: data,
});

export const getMMSCodeTable = (data) => request('/api/businessOppt/getMMSCodeTable', {
  method: 'get',
  body: data,
});

// 导入
export const upload = (data) => request('/api/opptCase/import', {
  method: 'post',
  body: data,
});

// 获取system config数据
export const getSystemConfigList = (data) => request('/api/common/system/config/list', {
  method: 'get',
  params: data,
});
