export default [
  {
    path: '/operation',
    name: 'operation',
    component: () => import('@/views/Base/Base.vue'),
    redirect: '/operation/ticket',
    meta: {
      // 运营管理
      title: 'lang.cpq.menu.operation',
      breadcrumb: [{ title: 'lang.cpq.menu.operation' }],
    },
    children: [
      {
        path: 'ticket',
        name: 'operationTicket',
        component: () => import('@/views/Base/Iframe.vue'),
        meta: {
          // 运营工单
          title: 'lang.cpq.menu.operation.ticket',
          breadcrumb: [
            { title: 'lang.cpq.menu.operation', path: '/operation/ticket' },
            // 运营工单
            { title: 'lang.cpq.menu.operation.ticket' },
          ],
        },
      },
    ],
  },
];
