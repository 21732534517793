// 方案评审
import request from '@/utils/requset';

export const getList = (data) => request('/api/opptCaseReview/paging', {
  method: 'get',
  params: data,
});

export const getSaleList = (data) => request('/api/opptCaseReview/sale/paging', {
  method: 'get',
  params: data,
});

export const getDetail = (data) => request('/api/opptCaseReview/item', {
  method: 'get',
  params: data,
});

export const getProductLineList = (data) => request('/api/basicData/list', {
  method: 'get',
  params: data,
});

export const update = (data) => request('/api/opptCaseReview/save', {
  method: 'post',
  body: data,
});

export const submit = (data) => request('/api/opptCaseReview/submit', {
  method: 'post',
  body: data,
});

export const deleteRow = (data) => request('/api/opptCaseReview/delete', {
  method: 'post',
  body: data,
});

export const withdraw = (data) => request('/api/opptCaseReview/withdraw', {
  method: 'post',
  body: data,
});

export const exportList = (data) => request('/api/opptCaseReview/batch/export', {
  method: 'post',
  body: data,
  responseType: 'arraybuffer',
});

export const getProductManagerList = (data) => request('/api/opptCaseReview/productManager/list', {
  method: 'get',
  params: data,
});

export const getSysCustomizeList = (data) => request('/api/opptCaseReview/sysCustomize/list', {
  method: 'get',
  params: data,
});

export const importEquList = (data) => request('/api/materials/checklist/template/import/equipmentList', {
  method: 'post',
  body: data,
  root: true,
  message: {
    show: false,
  },
});

export const materialExport = (data) => request('/api/opptCaseReview/material/export', {
  method: 'post',
  body: data,
  responseType: 'arraybuffer',
});

// 待审批
export const getListPending = (data) => request('/api/opptCaseReview/pending/paging', {
  method: 'get',
  params: data,
});

// 已审批
export const getListApproved = (data) => request('/api/opptCaseReview/approved/paging', {
  method: 'get',
  params: data,
});

// 已发起
export const getListInitiated = (data) => request('/api/opptCaseReview/initiated/paging', {
  method: 'get',
  params: data,
});

// 已完成
export const getListCompleted = (data) => request('/api/opptCaseReview/completed/paging', {
  method: 'get',
  params: data,
});

export const approve = (data) => request('/api/opptCaseReview/approve', {
  method: 'post',
  body: data,
});
