<template>
  <div class="s-p-action" :style="{textAlign: direction}">
    <slot></slot>
  </div>
</template>

<script>
import './style.scss';

export default {
  name: 'SPAction',
  props: {
    direction: {
      default: 'left',
      type: String,
    },
  },
};
</script>
