import Vue from 'vue';
import Element from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import NProgress from 'nprogress';
import 'nprogress/nprogress.css';

// SPComponents
import SPComponents from '@/components/SPComponents';
import ViewUI from 'view-design';
import ElSelectV2 from 'el-select-v2';
import App from './App.vue';
import router from './router/index';
import store from './store';
import rules from './utils/rules';
import './icons';
import i18n from './i18n/i18n';
import permission from './utils/permission';

// iview
import 'view-design/dist/styles/iview.css';

import '@/utils/directive';
import '@/utils/filter';

Vue.use(SPComponents);
Vue.prototype.$p = permission.has;
Vue.use(ViewUI, {
  i18n: (key, value) => i18n.t(key, value),
});
Vue.use(Element, {
  i18n: (key, value) => i18n.t(key, value),
});
Vue.use(ElSelectV2);
// 切换页面需要取消未完成的请求
Vue.prototype.cancelList = [];
Vue.prototype.$rules = rules;
Vue.config.productionTip = false;
// 配置默认progree
NProgress.configure({ trickleSpeed: 40, minimum: 0.6 });

// white list
const whiteList = ['/login', '/login-up', '/auth', '/author', '/forget-password', '/403', '/404', '/redirect', '/supply-chain'];

const loginPath = window.location.hostname === 'localhost' ? '/login-up' : '/login';

// router过滤
router.beforeEach(async (to, from, next) => {
  NProgress.start();
  if (store.state.user.menuData.length) {
    next();
    store.commit('app/updateState', {
      isCollapse: window.innerWidth < 1700,
    });
  } else if (whiteList.includes(to.path)) {
    next();
  } else {
    const res = await store.dispatch('user/initMenu');
    if (res) {
      await store.dispatch('user/initUser');
      await store.dispatch('app/initLang');
      await store.dispatch('app/initCodeTable');
      await store.dispatch('app/initMMSCodeTable');
      if (from.name === 'login') {
        const menu = store.state.user.menuData
          .find((item) => item.path === store.state.user.defaultPath);
        if (menu) {
          next(menu.path);
        } else {
          next('/home');
        }
      } else {
        next();
      }
      return;
    }
    next(loginPath);
    NProgress.done();
  }
});

// 路由结束的时候操作
router.afterEach(() => {
  NProgress.done();
});

// 实例化Vue
new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
