<template>
  <div :class="sPUploadClass">
    <Form>
      <!-- 预览 -->
      <Modal title="File Preview" width="1200px" :mask-closable="false" @on-cancel="previewVisible = false" v-model="previewVisible">
        <div class="s-p-upload__preview" :style="{height: previewHeight}">
          <img :src="previewImg" v-if="previewImg" class="s-p-upload__preview__img"/>
        </div>
        <div slot="footer">
          <Button @click="previewVisible = false">{{ $t('lang.cpq.equipment.label72') }}</Button>
        </div>
      </Modal>
      <template v-if="!isShowList">
        <div class="s-p-upload__card-item" :key="item.id" v-for="(item, index) in fileList">
          <div class="img-wrap">
            <template v-if="item.uploadStatus === 'finished'">
              <template>
                <img :src="item.url" v-if="isImage(item)">
                <Icon type="ios-paper-outline" v-else/>
              </template>
              <div class="s-p-upload__card-item__cover">
                <template>
                  <Icon type="ios-eye-outline" v-if="isImage(item)" @click.native="handleView(item)"></Icon>
                  <Icon type="md-download" v-else @click.native="handleDownload(item)"></Icon>
                </template>
                <Icon type="ios-trash-outline" v-if="!disabled && item.uploadStatus === 'finished'" @click.native="handleRemove(index, item)"></Icon>
              </div>
            </template>
            <template v-else>
              <Progress :percent="item.percentage" :stroke-width="5" hide-info></Progress>
              <p class="s-p-upload__percentage">{{ item.percentage.toFixed(2) }}%</p>
            </template>
          </div>
          <Tooltip :content="item.name" transfer placement="bottom-start" max-width="300px">
            <p class="s-p-upload__card-item__name">{{ item.name }}</p>
          </Tooltip>
        </div>
      </template>
      <Upload v-if="!disabled && !isMaxLimit" ref="upload"
              :show-upload-list="false"
              :default-file-list="fileList"
              :format="format"
              :accept="accept"
              :max-size="maxSize * 1024"
              :before-upload="handleBeforeUpload"
              with-credentials multiple action="/" class="s-p-upload__main">
        <Button v-if="isShowList">{{ $t('lang.cpq.quotation.label387') }}</Button>
        <div v-else class="s-p-upload__btn">
          <Icon type="md-add" size="20"></Icon>
        </div>
      </Upload>
      <div v-if="$slots['button-append']" style="display: inline-block; margin-left: 20px">
        <slot name="button-append"></slot>
      </div>
      <template v-if="isShowList">
        <div class="s-p-upload__list-item" :key="item.id" v-for="(item, index) in fileList">
          <div class="file-name">
            <a v-if="isImage(item)" @click="handleView(item)">{{ item.name }}</a>
            <a v-else @click="handleDownload(item)" target="_blank">{{ item.name }}</a>
            <Icon type="md-close-circle" v-if="!disabled && item.uploadStatus === 'finished'" @click="handleRemove(index, item)" class="file-name-icon"/>
          </div>
          <Progress :key="item.id + 'progress'" :percent="Math.floor(item.percentage)" hide-info v-if="item.uploadStatus !== 'finished'" :stroke-width="8"></Progress>
        </div>
      </template>
    </Form>
  </div>
</template>

<script>
import Emitter from 'view-design/src/mixins/emitter';
import request from '@/utils/requset';
import { mapState } from 'vuex';

const imageList = ['jpg', 'jpeg', 'png', 'gif'];
export default {
  name: 'SPUpload',
  mixins: [Emitter],
  props: {
    url: {
      type: String,
      default: '/api/systemDoc/upload/file',
    },
    format: {
      type: Array,
      default() {
        return [];
      },
    },
    maxSize: {
      type: Number,
      default: 20,
    },
    maxNumber: {
      type: Number,
      default: 0,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    accept: {
      type: String,
      default: '',
    },
    showType: {
      type: String,
      default: 'card',
    },
    defaultFileList: {
      type: Array,
      default() {
        return [];
      },
    },
    beforeRemove: {
      type: Function,
      default() {
        return () => {
        };
      },
    },
    beforeUpload: {
      type: Function,
      default() {
        return () => {
        };
      },
    },
  },
  data() {
    return {
      fileList: [],
      previewVisible: false,
      previewImg: '',
      initFilelist: false,
    };
  },
  computed: {
    ...mapState('app', ['windowSize']),
    uploadFn() {
      return (options) => request(this.url, options);
    },
    isShowList() {
      return this.showType === 'list';
    },
    isMaxLimit() {
      return this.maxNumber && this.fileList.length >= this.maxNumber;
    },
    sPUploadClass() {
      const classList = ['s-p-upload'];
      if (this.isShowList) {
        classList.push('show-list');
      }
      return classList;
    },
    previewHeight() {
      return `${this.windowSize.height - 300}px`;
    },
    upFormat() {
      if (this.format.length) {
        return this.format.map((item) => item.toUpperCase());
      }
      return [];
    },
  },
  watch: {
    defaultFileList: {
      handler(val) {
        this.useDefaultFileList(val);
      },
      immediate: true,
    },
  },
  created() {
    this.useDefaultFileList(this.defaultFileList);
  },
  methods: {
    useDefaultFileList(defaultFileList) {
      this.fileList = defaultFileList.map((file) => ({
        id: file.id,
        name: file.name,
        url: file.url,
        uploadStatus: 'finished',
      }));
    },
    isImage(item) {
      return imageList.includes(this.getSuffix(item.name));
    },
    handleView(item) {
      this.previewImg = item.url;
      this.previewVisible = true;
    },
    handleRemove(index, item) {
      const deleteItem = () => {
        this.fileList.splice(index, 1);
        this.$emit('on-change', index, JSON.parse(JSON.stringify(this.fileList)));
        this.$emit('update:default-file-list', JSON.parse(JSON.stringify(this.fileList)));
        this.dispatch('FormItem', 'on-form-change', JSON.parse(JSON.stringify(this.fileList)));
      };
      this.$Modal.confirm({
        title: 'Delete Confirm',
        content: 'Are you sure delete this file ?',
        okText: 'Yes',
        cancelText: 'Cancel',
        onOk: async () => {
          if (item.uploadStatus !== 'finished') {
            deleteItem();
            return;
          }
          if (this.beforeRemove && typeof this.beforeRemove === 'function') {
            if (await this.beforeRemove(index, item)) {
              deleteItem();
            } else {
              deleteItem();
            }
          } else {
            deleteItem();
          }
        },
      });
    },
    handleSuccess(res, name) {
      this.$Message.success({
        content: `${name || res.name} uploaded successfully`,
        duration: 3,
      });
      const isEnd = this.fileList.every((item) => item.uploadStatus === 'finished');
      this.$emit('on-success', res, isEnd);
    },
    handleFormat(file) {
      const suffix = this.getSuffix(file.name);
      if (suffix) {
        const upSuffix = suffix.toUpperCase();
        if (this.upFormat.length && !this.upFormat.includes(upSuffix)) {
          this.$Message.error({
            content: `The format of the file named ${file.name} should be one of ${JSON.stringify(this.format)}`,
            duration: 10,
          });
          return false;
        }
      }
      return true;
    },
    handleMaxSize(file) {
      if (file.size > this.maxSize * 1024 * 1024) {
        this.$Message.error({
          content: `The size of the file named ${file.name} should be less than ${this.maxSize}MB`,
          duration: 10,
        });
        return false;
      }
      return true;
    },
    handleUploadProgress(e, fileItem) {
      const { loaded, total } = e;
      fileItem.percentage = loaded / total * 100;
    },
    handleBeforeUpload(file) {
      if (this.isMaxLimit) {
        return false;
      }
      const isFormat = this.handleFormat(file);
      if (isFormat) {
        const isMaxSize = this.handleMaxSize(file);
        if (isMaxSize) {
          this.upload(file);
        }
      }
      return false;
    },
    async upload(file) {
      const formData = new FormData();
      formData.append('file', file);
      this.beforeUpload(file, formData);
      const newId = `${this.fileList.length}_${file.name}_${new Date().valueOf().toString(16)}`;
      const fileItem = {
        id: newId,
        uploadStatus: 'processing',
        percentage: 0,
        name: file.name,
      };
      this.fileList.push(fileItem);
      this.$nextTick(async () => {
        const res = await this.uploadFn({
          method: 'post',
          body: formData,
          events: {
            onUploadProgress: (e) => this.handleUploadProgress(e, fileItem),
          },
        });
        const index = this.fileList.findIndex((item) => item.id === newId);
        if (res) {
          const newFileItem = { ...this.fileList[index] };
          Object.assign(newFileItem, {
            url: res,
            uploadStatus: 'finished',
          });
          this.fileList.splice(index, 1, newFileItem);
          this.handleSuccess(res, fileItem.name);
          this.$emit('on-change', index, JSON.parse(JSON.stringify(this.fileList)));
          this.$emit('update:default-file-list', JSON.parse(JSON.stringify(this.fileList)));
          this.dispatch('FormItem', 'on-form-change', JSON.parse(JSON.stringify(this.fileList)));
        } else {
          this.fileList.splice(index, 1);
          this.$emit('on-change', index, JSON.parse(JSON.stringify(this.fileList)));
          this.$emit('update:default-file-list', JSON.parse(JSON.stringify(this.fileList)));
          this.dispatch('FormItem', 'on-form-change', JSON.parse(JSON.stringify(this.fileList)));
        }
      });
    },
    handleDownload(item) {
      if (item.url) {
        const a = document.createElement('a');
        a.download = item.name;
        a.href = item.url;
        a.click();
      } else {
        this.$Message.error({
          content: this.$t('lang.muse.label.file.not.found'),
          duration: 10,
        });
      }
    },
    getSuffix(name) {
      if (name) {
        const nameSplit = name.split('.');
        return nameSplit[nameSplit.length - 1];
      }
      return '';
    },
    // 暴露的接口
    getFileList() {
      return this.fileList.map((item) => ({
        id: item.id,
        name: item.name,
        url: item.url,
      }));
    },
  },
};
</script>

<style lang="scss">
$prefix: "s-p-upload";
.#{$prefix} {
  &.show-list {
    .ivu-upload-drag {
      border: none;
    }

    .#{$prefix}__list-item {
      display: block;
      height: auto;
      margin: 0;
      vertical-align: middle;
      width: 100%;

      .file-name {
        padding-left: 8px;
        position: relative;
        padding-right: 50px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        line-height: 30px;
        height: 30px;

        &:hover {
          background-color: #eee;

          .file-name-icon {
            display: block;
          }
        }

        &-icon {
          position: absolute;
          right: 10px;
          top: 0;
          font-size: 20px;
          line-height: 30px;
          color: #aaa;
          display: none;

          &:hover {
            cursor: pointer;
            color: #333;
          }
        }
      }

      .ivu-progress {
        line-height: 1;
      }
    }
  }

  &__card-item {
    display: inline-block;
    width: 80px;
    height: 100px;
    margin-right: 8px;
    margin-bottom: 8px;
    position: relative;
    vertical-align: top;

    .img-wrap {
      height: 80px;
      text-align: center;
      line-height: 80px;
      font-size: 50px;
      border: 1px solid #ddd;
      border-radius: 4px;
      position: relative;

      img {
        width: 100%;
        height: 100%;
      }

      &:hover {
        .s-p-upload__card-item__cover {
          display: block;
        }
      }
    }

    &__name {
      line-height: 22px;
      height: 22px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &__cover {
      display: none;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 80px;
      background: rgba(0, 0, 0, 0.6);
      text-align: center;
      line-height: 80px;

      .ivu-icon {
        color: #fff;
        font-size: 26px;
        cursor: pointer;
        margin: 0 4px;
        font-weight: bold;
        vertical-align: middle;
      }
    }

    .s-p-upload__percentage {
      font-size: 12px;
      position: absolute;
      top: 65%;
      width: 100%;
      left: 0;
      line-height: 1;
    }

    .ivu-tooltip {
      width: 100%;
    }

    .ivu-progress {
      vertical-align: top;
      width: 90%;
    }
  }

  &__main {
    display: inline-block;
    width: 80px;
  }

  &__btn {
    width: 80px;
    height: 80px;
    line-height: 80px;
    border: 1px solid #ddd;
    text-align: center;
    cursor: pointer;
  }

  &__preview {
    text-align: center;
    overflow: auto;

    &__img {
      max-width: 100%;
      height: 100%;
    }
  }
}
</style>
