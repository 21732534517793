<template>
  <Col :span="colSpan" class="item-wrap">
    <p class="search-box-item-label">{{ label }}<span v-if="label"></span></p>
    <slot></slot>
  </Col>
</template>

<script>

export default {
  name: 'SearchBoxItem',
  inject: ['enterClick'],
  props: {
    label: {
      default: '',
      type: String,
      required: true,
    },
    span: {
      default: 6,
      type: Number,
    },
  },
  computed: {
    colSpan() {
      if (this.span > 24) {
        return 24;
      }
      if (this.span < 1) {
        return 6;
      }
      return this.span;
    },
  },
  mounted() {
    if (this.enterClick) {
      this.$slots.default.forEach((vNode) => {
        if (vNode && vNode.child) {
          vNode.child.$on('on-enter', this.$parent.$parent.handleSearch);
        }
      });
    }
  },
};
</script>
