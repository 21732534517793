import TreeSelect from '@riophae/vue-treeselect';
import SPSearchBox from './SPSearchBox/SPSearchBox.vue';
import SPSearchBox2 from './SPSearchBox2/SPSearchBox2.vue';
import SPSearchBoxItem from './SPSearchBox/SPSearchBoxItem.vue';
import SPDataGrid from './SPDataGrid/SPDataGrid.vue';
import SPAction from './SPAction/SPAction.vue';
import SPUpload from './SPUpload/SPUpload.vue';
import SPImportFile from './SPImportFile/SPImportFile.vue';
import SPIconInfo from './SPIconInfo/SPIconInfo.vue';
import SPFormatInput from './SPFormatInput/SPFormatInput.vue';
import SPSteps from './SPSteps/SPSteps.vue';
import SPEditCell from './SPEditCell/SPEditCell.vue';
import SPNoData from './SPNoData/SPNoData.vue';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';

const SPComponents = {
  SPSearchBox,
  SPSearchBoxItem,
  SPSearchBox2,
  SPDataGrid,
  SPAction,
  SPUpload,
  SPImportFile,
  SPIconInfo,
  SPFormatInput,
  SPSteps,
  TreeSelect,
  SPEditCell,
  SPNoData,
};

const install = (Vue) => {
  if (install.installed) return;
  Object.keys(SPComponents).forEach((key) => {
    Vue.component(key, SPComponents[key]);
  });
};

// auto install
if (typeof window !== 'undefined' && window.Vue) {
  install(window.Vue);
}

const output = {
  install,
  ...SPComponents,
};

export default output;
