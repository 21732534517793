export default [
  {
    path: 'workbench/to-do',
    name: 'workbenchToDo',
    component: () => import('@/views/Workbench/Index'),
    meta: {
      title: 'lang.cpq.menu.workbench.toDo',
      icon: 'workbench',
    },
  },
  {
    path: 'approval-todo/:id',
    name: 'workbenchApprovalTodo',
    component: () => import('@/views/Workbench/Todo/Approval.vue'),
    meta: {
      title: 'lang.cpq.action.tobe', // 待审批
      breadcrumb: [{ title: 'lang.cpq.menu.workbench.toDo', path: '/workbench/to-do' }, { title: 'lang.cpq.action.tobe' }],
    },
  },
  {
    path: 'approval-done/:id',
    name: 'workbenchApprovalDone',
    component: () => import('@/views/Workbench/Todo/Approval.vue'),
    meta: {
      title: 'lang.cpq.action.already', // 待审批
      breadcrumb: [{ title: 'lang.cpq.menu.workbench.toDo', path: '/workbench/to-do' }, { title: 'lang.cpq.action.already' }],
    },
  },
  {
    path: 'approval-initiate/:id',
    name: 'workbenchApprovalCommitted',
    component: () => import('@/views/Quotation/Plan/Create.vue'),
    meta: {
      title: 'lang.cpq.action.initiate', // 待审批
      breadcrumb: [{ title: 'lang.cpq.menu.workbench.toDo', path: '/workbench/to-do' }, { title: 'lang.cpq.action.initiate' }],
    },
  },
  {
    path: '/workbench/impToDo',
    name: 'workbenchImpToDo',
    component: () => import('@/views/Workbench/Implement/Index'),
    meta: {
      title: 'lang.cpq.menu.earlyImplementationApprove',
      icon: 'workbench',
    },
  },
  {
    path: 'approval-imp-todo',
    name: 'workbenchImpApprovalTodo',
    component: () => import('@/views/Workbench/Implement/Todo/Approval.vue'),
    meta: {
      title: 'lang.cpq.action.tobe', // 待审批
      breadcrumb: [{ title: 'lang.cpq.menu.workbench.toDo', path: '/workbench/impToDo' }, { title: 'lang.cpq.action.tobe' }],
    },
  },
  {
    path: 'approval-imp-done',
    name: 'workbenchImpApprovalDone',
    component: () => import('@/views/Workbench/Implement/Todo/Approval.vue'),
    meta: {
      title: 'lang.cpq.action.tobe', // 待审批
      breadcrumb: [{ title: 'lang.cpq.menu.workbench.toDo', path: '/workbench/impToDo' }, { title: 'lang.cpq.action.tobe' }],
    },
  },
  // 预报价
  {
    path: 'pre-approval-initiate/:id',
    name: 'preWorkbenchApprovalCommitted',
    component: () => import('@/views/Quotation/PrePlan/Create.vue'),
    meta: {
      title: 'lang.cpq.action.initiate', // 待审批
      breadcrumb: [{ title: 'lang.cpq.menu.workbench.toDo', path: '/workbench/to-do' }, { title: 'lang.cpq.action.initiate' }],
    },
  },
];
