export default [
  {
    path: 'channel',
    name: 'channel',
    component: () => import('@/views/Base/Base'),
    meta: {
      title: 'lang.cpq.menu.channel.list',
      icon: 'channel',
    },
    children: [
      // 账号管理
      {
        path: 'list',
        name: 'channelList',
        component: () => import('@/views/Channel/List.vue'),
        meta: {
          title: 'lang.cpq.menu.channel.list',
          icon: 'channel',
          breadcrumb: [{ title: 'lang.cpq.menu.channel.list', path: '/channel/list' }],
        },
      },
    ],
  },
];
