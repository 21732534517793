export default [
  {
    path: 'program-review',
    name: 'programReview',
    component: () => import('@/views/Base/Base'),
    meta: {
      title: 'lang.cpq.menu.programReview', // 方案评审
      icon: 'approve',
    },
    children: [
      // 列表
      {
        path: 'list',
        name: 'programReviewList',
        component: () => import('@/views/ProgramReview/List'),
        meta: {
          title: 'lang.cpq.menu.equipment.list.template',
          breadcrumb: [{
            title: 'lang.cpq.menu.programReview',
            path: '/program-review/list',
          }, {
            title: 'lang.cpq.label.programReviewList',
          }],
        },
      },
      // 销售查看列表
      {
        path: 'sale-list',
        name: 'programReviewSaleList',
        component: () => import('@/views/ProgramReview/SaleList'),
        meta: {
          title: 'lang.cpq.menu.equipment.list.template',
          breadcrumb: [{
            title: 'lang.cpq.menu.programReview',
            path: '/program-review/sale-list',
          }, {
            title: 'lang.cpq.label.programReviewList',
          }],
        },
      },
      // 创建 / 修改 / 详情 / 审批
      {
        path: 'create',
        name: 'programReviewCreate',
        component: () => import('@/views/ProgramReview/Create'),
        meta: {
          title: 'lang.cpq.menu.equipment.list.template',
          breadcrumb: [{
            title: 'lang.cpq.menu.programReview',
            path: '/program-review/list',
          }, {
            title: 'lang.cpq.menu.programReview',
          }],
        },
      },
      // 审批列表
      {
        path: 'approval-list',
        name: 'programReviewApprovalList',
        component: () => import('@/views/ProgramReview/ApprovalList'),
        meta: {
          title: 'lang.cpq.menu.equipment.list.template',
          breadcrumb: [{
            title: 'lang.cpq.label.programReviewApproval',
          }],
        },
      },
    ],
  },
];
