import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

const modulesFiles = require.context('./modules', true, /\.js$/);

const mainRouterList = [];
modulesFiles.keys().forEach((item) => {
  const value = modulesFiles(item);
  mainRouterList.push(...value.default);
});
const loginPath = window.location.hostname === 'localhost' ? '/login-up' : '/login';

// 注册所有路由，如果有用户输入无权限的页面，则后台会报403，跳转到无权限页面
export default new Router({
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition = {}) => ({
    y: 0,
    ...savedPosition,
  }),
  routes: [
    {
      path: '/',
      redirect: loginPath,
      component: () => import('@/components/Layout/Layout.vue'),
      children: [{
        path: 'home',
        name: 'home',
        component: () => import('../views/Dashboard/Dashboard'),
        meta: {
          title: 'lang.cpq.menu.home',
          icon: 'calendar',
          breadcrumb: [{ title: 'lang.cpq.menu.home' }],
        },
      },
      ...mainRouterList,
      {
        name: '403',
        path: '/403',
        component: () => import('../components/errorAllow/errorAllow'),
      },
      {
        name: '404',
        path: '/404',
        component: () => import('../components/errorMiss/errorMiss'),
      }],
    },
    {
      name: 'login',
      path: '/login',
      component: () => import('@/views/Login/Login'),
    },
    {
      name: 'login-up',
      path: '/login-up',
      component: () => import('@/views/Login/LoginUP'),
    },
    {
      name: 'auth',
      path: '/auth',
      component: () => import('@/views/Login/Auth'),
    },
    {
      name: 'author',
      path: '/author',
      component: () => import('@/views/Login/Author'),
    },
    {
      name: 'forget-password',
      path: '/forget-password',
      component: () => import('../views/Login/ForgetPassword'),
    },
    {
      name: 'redirect',
      path: '/redirect',
      component: () => import('@/views/Login/Redirect'),
    },
    // gop引入趋势图表
    {
      name: 'gop-robot-price-trend',
      path: '/gop/robot-price-trend',
      component: () => import('@//views/RobotPriceTrend/RobotPriceTrend'),
    },
    // 仿真规划物料页面 - 无权限
    {
      name: 'simu-planning',
      path: '/simu/planning',
      component: () => import('@/views/Simu/Index.vue'),
    },
    // poc供应链回收，无权限
    {
      path: '/supply-chain',
      name: 'supplyChain',
      component: () => import('@/views/POC/SupplyChain'),
    },
  ],
});
