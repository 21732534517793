import { getLanguageInfo, setLangPack, getLanguage } from '@/i18n/i18n';
import {
  getCodeTable,
  getMMSCodeTable,
  getI18nByLanguage,
  getSystemConfigList,
} from '@/service/app';
import { firstCharUpper } from '@/utils/util';
import { getProductLineList } from '@/service/programReview';

const lang = firstCharUpper(getLanguage());

export default {
  namespaced: true,
  state: {
    codeTable: null,
    mmsCodeTable: null,
    systemConfigList: [],
    windowSize: {
      width: window.innerWidth,
      height: window.innerHeight,
    },
    isCollapse: window.innerWidth < 1700,
    upperLang: lang || 'En',
    isERP: true,
    hideMenu: false,
  },
  mutations: {
    SET_CODE_TABLE(state, codeTable) {
      state.codeTable = codeTable;
    },
    SET_MMS_CODE_TABLE(state, codeTable) {
      state.mmsCodeTable = codeTable;
    },
    updateState(state, payload) {
      Object.assign(state, payload);
    },
    setHideMenu(state, value) {
      state.hideMenu = value;
    },
  },
  actions: {
    async initLang() {
      const { lang, fullName } = getLanguageInfo();
      const result = await getI18nByLanguage(fullName);
      setLangPack(lang, result);
    },
    async initCodeTable({ dispatch, state, commit }, isReload = false) {
      let { codeTable } = state;
      const { systemConfigList } = state;

      if (!codeTable) {
        const resList = await Promise.all([
          getCodeTable(),
          getProductLineList(),
        ]);
        if (resList.length && resList.every((res) => !!res)) {
          codeTable = resList[0].map((i) => ({
            ...i,
            describe: i.description,
          }));
          codeTable = codeTable.filter((item) => item.codeType !== 'PRODUCT_LINE_TYPE_CRM');
          resList[1].forEach((item) => {
            const {
              productLineCode, basicDataCodeDescCn, basicDataCodeDescEn,
            } = item;
            codeTable.push({
              activeStatus: 1,
              code: productLineCode,
              codeDescCn: basicDataCodeDescCn,
              codeDescEn: basicDataCodeDescEn,
              codeType: 'PRODUCT_LINE_TYPE_CRM',
            });
          });
          dispatch('setCodeTable', codeTable);
        }
      }

      if (!systemConfigList.length || isReload) {
        const res = await getSystemConfigList();
        if (res) {
          dispatch('setSystemConfig', res);
        }
      }
    },
    async initMMSCodeTable({ dispatch, state }, isReload = false) {
      let { mmsCodeTable } = state;
      const { systemConfigList } = state;

      if (!mmsCodeTable) {
        const resList = await Promise.all([
          getMMSCodeTable(),
        ]);
        if (resList.length && resList.every((res) => !!res)) {
          mmsCodeTable = resList[0];
          dispatch('setMmsCodeTable', mmsCodeTable);
        }
      }
    },
    setCodeTable({ commit }, codeTable) {
      sessionStorage.setItem('codeTable', JSON.stringify(codeTable));
      commit('SET_CODE_TABLE', codeTable);
    },
    setMmsCodeTable({ commit }, mmsCodeTable) {
      sessionStorage.setItem('mmsCodeTable', JSON.stringify(mmsCodeTable));
      commit('SET_MMS_CODE_TABLE', mmsCodeTable);
    },
    setSystemConfig({ commit }, systemConfigList) {
      sessionStorage.setItem('systemConfigList', JSON.stringify(systemConfigList));
      commit('updateState', {
        systemConfigList,
      });
    },
  },
};
