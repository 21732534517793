export default [
  {
    path: 'help',
    name: 'help',
    component: () => import('@/views/Base/Base'),
    meta: {
      title: 'lang.cpq.menu.help',
      icon: 'help',
    },
    children: [
      // 使用统计
      {
        path: 'system-use-statistics',
        name: 'lang.cpq.menu.help.systemUseStatistics',
        component: () => import('@/views/Help/SystemUseStatistics.vue'),
        meta: {
          title: 'lang.cpq.menu.help.systemUseStatistics',
          breadcrumb: [{ title: 'lang.cpq.menu.help', path: '/help/system-use-statistics' }, { title: 'lang.cpq.menu.help.systemUseStatistics' }],
        },
      },
      // 系统运维
      {
        path: 'system-operation-and-maintenance',
        name: 'systemOperationAndMaintenance',
        component: () => import('@/views/Help/Maintenance/List.vue'),
        meta: {
          title: '系统运维',
          breadcrumb: [{ title: 'lang.cpq.menu.help', path: '/help/system-operation-and-maintenance' }, { title: '系统运维' }],
        },
      },
      // 操作文档
      {
        path: 'operating-instructions',
        name: 'operatingInstructions',
        component: () => import('@/views/Help/Instruction/List.vue'),
        meta: {
          title: '系统运维',
          breadcrumb: [{ title: 'lang.cpq.menu.help', path: '/help/operating-instructions' }, { title: '系统运维' }],
        },
      },
      // 培训文档
      {
        path: 'training-documents',
        name: 'trainingDocuments',
        component: () => import('@/views/Help/Instruction/List.vue'),
        meta: {
          title: '系统运维',
          breadcrumb: [{ title: 'lang.cpq.menu.help', path: '/help/operating-instructions' }, { title: '培训文档' }],
        },
      },
      {
        path: 'feedback',
        name: 'feedback',
        component: () => import('@/views/Feedback/index.vue'),
        meta: {
          title: 'lang.cpq.menu.help.feedback',
          icon: 'feedback',
          breadcrumb: [{
            title: 'lang.cpq.menu.help.feedback',
          }],
        },
      },
      {
        path: 'operation-manual',
        name: 'operationManual',
        component: () => import('@/views/Help/OperationManual/index.vue'),
        meta: {
          title: 'lang.cpq.menu.help.operationManual',
          breadcrumb: [{
            title: 'lang.cpq.menu.help.operationManual',
          }],
        },
      }, {
        path: 'upgrade-information',
        name: 'releaseLog',
        component: () => import('@/views/docs/docs.vue'),
        meta: {
          title: 'lang.cpq.menu.help.upgradeInformation',
          breadcrumb: [{
            title: 'lang.cpq.menu.help.upgradeInformation',
          }],
        },
      },
    ],
  },
];
