<template>
  <div class="s-p-data-grid">
    <Table :data="dataList" :loading="loading"
           :columns="tableColumns"
           :border="border"
           :height="newHeight"
           :maxHeight="maxHeight"
           :size="size"
           :refreshTable="refreshTable"
           :row-key="rowKey"
           :row-class-name="rowClassName"
           :span-method="spanMethod"
           @on-sort-change="handleSortChange"
           @on-selection-change="tableSelectChange"
           @on-filter-change="handleFilterChange" :stripe="stripe" ref="pubTable">
      <template v-for="item in tableColumns"
                :slot="item.slot" slot-scope="{ row, index, tableColumns }">
        <template v-if="item.slot">
          <slot :name="item.slot" v-bind="{ row, index, tableColumns }"></slot>
        </template>
      </template>
    </Table>
    <div class="s-p-data-grid__page" v-if="page">
      <div class="s-p-data-grid__page__scroll">{{ $t('lang.cpq.quotation.tip') }}</div>
      <Page v-bind="pageProps"
            @on-change="pageEventProps['on-change']"
            @on-page-size-change="pageEventProps['on-page-size-change']"
            :show-sizer="!page.isHideSizer"
            :show-elevator="!page.isHideElevator"
            :show-total="!page.isHideTotal"
            transfer>
      </Page>
    </div>
  </div>
</template>

<script>
import './style.scss';
import i18n from '@/i18n/i18n';
import { humpToLine } from '../utils/util';

// 生成按钮list
const generateButtonList = (h, item, params, showAllButton, isGhostButton = false) => {
  const buttonStr = isGhostButton ? 'Button' : 'a';
  const { index, row } = params;

  // 兼容renderButton是方法的情况
  let renderButton = typeof item.renderButton === 'function'
    ? item.renderButton({ index, row })
    : [...item.renderButton];
  renderButton = renderButton.map((btn) => {
    if (isGhostButton) {
      return Object.assign(btn, {
        style: {
          margin: '2px',
          ...btn.style,
        },
      });
    }
    return btn;
  });
  // 未渲染之前的按钮
  const buttonList = [];
  // 如果按钮多于三个，则显示更多
  if (renderButton.length > 3 && !showAllButton) {
    const showButtons = renderButton.slice(0, 2);
    showButtons.forEach((button) => {
      buttonList.push(
        h(buttonStr, {
          props: {
            size: 'small',
            ghost: true,
            type: 'primary',
          },
          style: button.style ? button.style : {},
          on: (() => {
            // 绑定按钮事件
            const eventsObj = {};
            if (button.on && Object.keys(button.on).length) {
              Object.keys(button.on).forEach((event) => {
                const fn = button.on[event];
                Object.assign(eventsObj, {
                  [event]: (e) => {
                    fn({ index, row }, e);
                  },
                });
              });
            }
            return eventsObj;
          })(),
        }, button.label),
      );
      if (!isGhostButton) {
        buttonList.push(
          h('span', {
            props: {
              type: 'text',
            },
            class: 'divider',
          }, ''),
        );
      }
    });
    const dropDownList = [];
    renderButton.forEach((button, i) => {
      if (i > 1) {
        dropDownList.push(
          h('DropdownItem', {
            props: {
              name: `${i}_${button.label}`,
            },
            style: button.style ? button.style : {},
          }, button.label),
        );
      }
    });
    buttonList.push(
      h('Dropdown', {
        props: {
          transfer: true,
          placement: 'bottom-end',
        },
        on: {
          'on-click': (name) => {
            const i = name.split('_')[0];
            renderButton[i].on.click({ index, row });
          },
        },
      }, [
        h('a', [
          h('span', i18n.t('lang.cpq.label.more')),
          h('Icon', {
            props: {
              type: 'ios-arrow-down',
            },
          }),
        ]),
        h('DropdownMenu', {
          slot: 'list',
        }, dropDownList),
      ]),
    );
  } else {
    renderButton.forEach((button, i) => {
      // 增加分割线
      if (i !== 0 && !isGhostButton) {
        buttonList.push(
          h('span', {
            props: {
              type: 'text',
            },
            class: 'divider',
          }, ''),
        );
      }
      // 绑定按钮事件
      if (button.on && Object.keys(button.on).length) {
        Object.keys(button.on).forEach((event) => {
          const fn = button.on[event];
          Object.assign(button, {
            on: {
              [event]: (e) => {
                fn({ index, row }, e);
              },
            },
          });
        });
      }
      // 渲染按钮
      if (button.confirm) {
        // 判断是否需要二次确认
        const poptipProps = (props = {}) => {
          const {
            title = `${i18n.t('lang.cpq.info.delete.are.you.sure')}?`,
            okText = i18n.t('lang.cpq.action.confirm'),
            cancelText = i18n.t('lang.cpq.button.cancel'),
          } = props;
          return {
            transfer: true,
            confirm: true,
            placement: 'top-end',
            width: '300px',
            title,
            okText,
            cancelText,
          };
        };
        // 如果为true，则使用默认提示
        if (typeof button.confirm === 'boolean' && button.confirm) {
          buttonList.push(
            h('Poptip', {
              props: poptipProps({}),
              on: {
                'on-ok': button.on.click,
              },
            }, [
              h(buttonStr, {
                props: {
                  size: 'small',
                  ghost: true,
                  type: 'primary',
                },
                style: button.style ? button.style : {},
              }, button.label),
            ]),
          );
        } else {
          buttonList.push(
            h('Poptip', {
              props: poptipProps(button.confirm),
              on: {
                'on-ok': button.on.click,
              },
            }, [
              h(buttonStr, {
                props: {
                  size: 'small',
                  ghost: true,
                  type: 'primary',
                },
                style: button.style ? button.style : {},
              }, button.label),
            ]),
          );
        }
      } else {
        buttonList.push(
          h(buttonStr, {
            props: {
              size: 'small',
              ghost: true,
              type: 'primary',
            },
            style: button.style ? button.style : {},
            on: button.on,
          }, button.label),
        );
      }
    });
  }
  return buttonList;
};

export default {
  name: 'DataGrid',
  props: {
    border: Boolean,
    loading: Boolean,
    size: String,
    refreshTable: Boolean,
    columns: {
      type: Array,
      required: true,
      default() {
        return [];
      },
    },
    data: {
      type: Array,
      required: true,
      default() {
        return [];
      },
    },
    page: {
      type: [Boolean, Object],
      required: true,
      default: false,
    },
    rowClassName: {
      type: Function,
      default() {
        return () => { };
      },
    },
    order: {
      type: Boolean,
      default: true,
    },
    showAllButton: {
      type: Boolean,
      default: true,
    },
    height: {
      type: [Number, String],
      default: '',
    },
    maxHeight: {
      type: [Number, String],
      default: '',
    },
    rowKey: {
      type: String,
      default: '',
    },
    spanMethod: {
      type: Function,
      default() {
        return () => {};
      },
    },
    orderColumnWidth: {
      type: Number,
      default: 60,
    },
    stripe: {
      type: Boolean,
      default: true,
    },
    isGhostButton: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  // 处理columns
  computed: {
    tableColumns() {
      const columns = [...this.columns];
      if (this.order && columns.length) {
        // 添加序号头
        const columnsIndex = {
          title: '#',
          key: '_columnsIndex',
          width: this.orderColumnWidth,
        };
        if (columns[0].fixed === 'left') {
          Object.assign(columnsIndex, {
            fixed: 'left',
          });
        }
        if (columns[0].type === 'selection') {
          columns.splice(1, 0, columnsIndex);
        } else {
          columns.unshift(columnsIndex);
        }
      }

      columns.forEach((item) => {
        // 按钮事件
        if (item.renderButton) {
          Object.assign(item, {
            render: (h, params) => h('div', generateButtonList(h, item, params, this.showAllButton, this.isGhostButton)),
          });
        }
      });
      return columns;
    },
    // 表格高度
    newHeight() {
      if (this.height) {
        return this.page ? this.height - 50 : this.height;
      }
      return undefined;
    },
    // page属性处理
    pageProps() {
      const pageProps = {};
      if (this.page && Object.keys(this.page).length) {
        Object.keys(this.page).forEach((item) => {
          pageProps[humpToLine(item)] = this.page[item];
        });
      }
      return pageProps;
    },
    // pageEvent属性处理
    pageEventProps() {
      // 首先将默认的event事件加到pageEventProps中
      const pageEventProps = {
        'on-change': this.defaultPageFn,
        'on-page-size-change': this.defaultPageFn,
      };
      // 然后将外面传进来的方法进行替换
      if (this.page && this.page.on && Object.keys(this.page.on).length) {
        Object.keys(this.page.on).forEach((item) => {
          pageEventProps[humpToLine(item)] = this.page.on[item];
        });
      }
      return pageEventProps;
    },
    // table refs
    tableRefs() {
      return this.$refs.pubTable;
    },
    dataList() {
      const {
        load = true, current = 1, pageSize = 20,
      } = this.page;
      const list = load ? this.data : this.data.slice((current - 1) * pageSize, current * pageSize);
      // 增加序号
      list.forEach((item, index) => {
        Object.assign(item, {
          _columnsIndex: pageSize * (current - 1) + index + 1,
        });
      });
      return list;
    },
  },
  created() { },
  methods: {
    defaultPageFn() { },
    handleSortChange(obj) {
      this.$emit('on-sort-change', obj);
    },
    tableSelectChange(obj) {
      this.$emit('on-selection-change', obj);
    },
    handleFilterChange(obj) {
      this.$emit('on-filter-change', obj);
    },
  },
};
</script>
