<template>
  <div class="v-format-input">
    <Input :value="value"
           v-bind="$attrs"
           :placeholder="placeholder"
           :clearable="clearable"
           :disabled="disabled"
           :readonly="readonly"
           :element-id="elementId"
           @on-change="handleValueChange"
           @on-blur="handleValueBlur"
           @on-focus="$emit('on-focus')">
      <template slot="append">
        <slot name="append"></slot>
      </template>
    </Input>
    <slot></slot>
  </div>
</template>

<script>
import Emitter from 'view-design/src/mixins/emitter';

export default {
  name: 'index',
  mixins: [Emitter],
  data() {
    return {
      newValue: '',
    };
  },
  props: {
    value: [Number, String],
    placeholder: String,
    clearable: Boolean,
    disabled: Boolean,
    readonly: Boolean,
    elementId: String,
    changeType: {
      type: String,
      default: 'change',
    },
    format: {
      type: String,
      default: 'positiveInteger',
    },
  },
  methods: {
    handleValueChange(e) {
      const { value } = e.target;
      const { pattern } = this.$rules[this.format];
      this.newValue = value;
      if (this.changeType === 'blur') {
        return;
      }
      const oldValue = this.value;
      this.$emit('input', value);
      this.$emit('on-change', value);
      this.dispatch('FormItem', 'on-form-change', value);
      if (value && !pattern.test(value)) {
        this.$nextTick(() => {
          this.$emit('input', oldValue);
          this.$emit('on-change', oldValue);
          this.dispatch('FormItem', 'on-form-change', value);
        });
      }
    },
    handleValueBlur() {
      this.$emit('on-blur');
      if (this.changeType === 'change') {
        return;
      }
      const oldValue = this.value;
      const { pattern } = this.$rules[this.format];
      this.$emit('input', this.newValue);
      this.$emit('on-change', this.newValue);
      this.dispatch('FormItem', 'on-form-change', this.newValue);
      if (this.newValue && !pattern.test(this.newValue)) {
        this.$nextTick(() => {
          this.$emit('input', oldValue);
          this.$emit('on-change', oldValue);
          this.dispatch('FormItem', 'on-form-change', oldValue);
        });
      }
    },
  },
};
</script>

<style lang="scss">
  .v-format-input{
    display: inline-block;
    width: 100%;
    .ivu-input[disabled],
    fieldset[disabled] .ivu-input{
      color: #333;
    }
  }
</style>
