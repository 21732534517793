import store from '@/store/index';
import i18n from "@/i18n/i18n";
import router from "@/router";
import { Message } from "view-design";

export const firstCharUpper = (string) => {
  if (string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  return '';
};

export const getCodeTableMap = (name) => {
  const list = store.state.app.codeTable?.filter((item) => item.codeType === name) || [];
  const obj = {};
  list.forEach((item) => {
    obj[item.code] = item[`codeDesc${store.state.app.upperLang}`] || item.codeDescCn;
  });
  return obj;
};

export const getMMSCodeTableMap = (name) => {
  const list = store.state.app.mmsCodeTable.filter((item) => item.codeType === name) || [];
  const obj = {};
  list.forEach((item) => {
    obj[item.code] = item[`codeDesc${store.state.app.upperLang}`] || item.codeDescCn;
  });
  return obj;
};

export const getSystemConfigMap = (name) => {
  const list = store.state.app.systemConfigList?.filter((item) => item.configType === name) || [];
  const obj = {};
  list.forEach((item) => {
    obj[item.configKey] = item.configValue;
  });
  return obj;
};

export const getOnlyKey = () => `${Math.ceil(new Date().getTime() * Math.ceil(Math.random() * 10000)).toString(16).toUpperCase()}`;

export function downloadFile(data, type, fileName) {
  let blob = new Blob([data], { type: `application/${type};charset=utf-8` });
  // 获取heads中的filename文件名
  let downloadElement = document.createElement('a');
  // 创建下载的链接
  let href = window.URL.createObjectURL(blob);
  downloadElement.href = href;
  downloadElement.setAttribute('target', '_blank');
  downloadElement.setAttribute('download', fileName);
  document.body.appendChild(downloadElement);
  // 点击下载
  downloadElement.click();
  // 下载完成移除元素
  document.body.removeChild(downloadElement);
  // 释放掉blob对象
  window.URL.revokeObjectURL(href);
}

export const paramsStringify = (params = {}) => {
  const paramsArray = [];
  Object.keys(params).forEach((k) => {
    let values = '';
    switch (typeof params[k]) {
      case 'string':
        values = params[k].trim();
        break;
      case 'undefined':
        return;
      default:
        values = params[k];
        break;
    }
    paramsArray.push(`${k}=${encodeURIComponent(values)}`);
  });
  return paramsArray.join('&');
};


export const validateMaxInput = (rule, value, callback, maxNum) => {
  if (value > maxNum) {
    callback(new Error(`${i18n.t('lang.cpq.template.label104')} ${maxNum}`)); // 最大只能输入 + maxNum
    return;
  }
  callback();
};

export const validateMinInput = (rule, value, callback, minNum) => {
  if (value < minNum) {
    callback(new Error(`${i18n.t('lang.cpq.template.label111')} ${minNum}`)); // 最小只能输入 + minNum
    return;
  }
  callback();
};

export const isNullOrUndefined = (inputValue) => {
  return inputValue === null || inputValue === undefined;
}

export const getNormalNumber = (inputValue) => {
  if (isNaN(inputValue) || !inputValue) {
    return 0;
  }
  return Number(inputValue);
};

export const priceRound = (inputValue) => {
  if (isNaN(String(inputValue)) || inputValue === '') {
    return ''
  }
  let number = Math.abs(inputValue);

  // 向下取整
  if (number < 1000) {
    // 不动
  } else if (number < 10000) {
    // 十位取整
    number = Math.floor(number / 10) * 10;
  } else if (number < 100000) {
    // 百位取整
    number = Math.floor(number / 100) * 100;
  } else {
    // 千位取整
    number = Math.floor(number / 1000) * 1000;
  }
  number = number.toFixed(2);
  return inputValue < 0 ? `-${number}` : number;
}

export const sleep = (time = 1000) => {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve()
    }, time)
  })
}

export const scrollIntoError = () => {
  setTimeout(() => {
    const errorList = document.querySelectorAll('.el-form-item__error, .ivu-form-item-error-tip');
    if (errorList.length) {
      errorList[0].scrollIntoView({
        block: 'center'
      });
    }
  });
}

export const toStringNumber = (value, fallback = '') => {
  if (value === null || value === undefined || isNaN(value)) {
    return fallback;
  }
  return String(value);
}

export const isNumberOrStringNumber = value => {
  return !isNaN(value) && value !== '' && value !== null;
}
