export default [
  {
    path: 'poc',
    name: 'poc',
    component: () => import('@/views/Base/Base'),
    meta: {
      title: 'lang.cpq.label.poc', // poc
      icon: 'approve',
    },
    children: [
      // 申请
      {
        path: 'application',
        name: 'pocApplication',
        component: () => import('@/views/POC/List'),
        meta: {
          title: 'lang.cpq.menu.equipment.list.template',
          breadcrumb: [{
            title: 'lang.cpq.label.poc',
            path: '/poc/application',
          }, {
            title: 'lang.cpq.label.pocApplication',
          }],
        },
      },
      // 列表
      {
        path: 'list',
        name: 'pocList',
        component: () => import('@/views/POC/List'),
        meta: {
          title: 'lang.cpq.menu.equipment.list.template',
          breadcrumb: [{
            title: 'lang.cpq.label.poc',
            path: '/poc/list',
          }, {
            title: 'lang.cpq.label.pocList',
          }],
        },
      },
      // 创建 / 修改 / 详情 / 审批
      {
        path: 'create',
        name: 'pocCreate',
        component: () => import('@/views/POC/Create'),
        meta: {
          title: 'lang.cpq.menu.equipment.list.template',
          breadcrumb: [{
            title: 'lang.cpq.label.poc',
            path: '/poc/list',
          }, {
            title: 'lang.cpq.label.poc',
          }],
        },
      },
      // 审批列表
      {
        path: 'approval-list',
        name: 'pocApprovalList',
        component: () => import('@/views/POC/ApprovalList'),
        meta: {
          title: 'lang.cpq.menu.equipment.list.template',
          breadcrumb: [{
            title: 'lang.cpq.label.pocApproval',
          }],
        },
      },
    ],
  },
];
