export default [
  {
    path: 'planning',
    name: 'planning',
    component: () => import('@/views/Base/Base'),
    meta: {
      title: 'lang.cpq.menu.planning.quote', // 规划报价
      icon: 'planning',
    },
    children: [
      // 创建报价清单
      {
        path: 'quote',
        name: 'planningQuote',
        component: () => import('@/views/Planning/EquList/List'),
        meta: {
          title: 'lang.cpq.menu.equipment.list.template',
          breadcrumb: [{
            title: 'lang.cpq.menu.planning.quote',
            path: '/planning/quote',
          }, {
            title: 'lang.cpq.quotation.label467',
          }],
        },
      },
      // 清单模板
      {
        path: 'template',
        name: 'planningTemp',
        component: () => import('@/views/Planning/EquListTemp/List'),
        meta: {
          title: 'lang.cpq.menu.equipment.list.template',
          breadcrumb: [{
            title: 'lang.cpq.menu.planning.quote',
            path: '/planning/template',
          }, {
            title: 'lang.cpq.quotation.label465',
          }],
        },
      },
      // 规划报价单 - 创建
      {
        path: 'quote-create',
        name: 'planningQuoteCreate',
        component: () => import('@/views/Planning/EquList/Create'),
        meta: {
          title: 'lang.cpq.menu.equipment.list.template',
          breadcrumb: [{
            title: 'lang.cpq.menu.planning.quote',
            path: '/planning/quote',
          }, {
            title: 'lang.cpq.quotation.label467',
          }],
        },
      },
      // 规划报价单 - 详情
      {
        path: 'quote-detail',
        name: 'planningQuoteDetail',
        component: () => import('@/views/Planning/EquList/Detail'),
        meta: {
          title: 'lang.cpq.menu.equipment.list.template',
          breadcrumb: [{
            title: 'lang.cpq.menu.planning.quote',
            path: '/planning/quote',
          }, {
            title: 'lang.cpq.quotation.label468',
          }],
        },
      },
      // 项目经理 - 报价单列表
      {
        path: 'project-manager-quote-list',
        name: 'projectManagerQuoteList',
        component: () => import('@/views/Planning/ProjectManagerQuote/List'),
        meta: {
          title: 'lang.cpq.menu.project.manager.quote.list',
          breadcrumb: [{
            title: 'lang.cpq.menu.project.manager.quote.list',
            path: '/planning/project-manager-quote-list',
          }],
        },
      },
      // 项目经理 - 报价单详情
      {
        path: 'project-manager-quote-detail',
        name: 'projectManagerQuoteDetail',
        component: () => import('@/views/Planning/ProjectManagerQuote/Detail'),
        meta: {
          title: 'lang.cpq.menu.project.manager.quote.list',
          breadcrumb: [{
            title: 'lang.cpq.menu.project.manager.quote.list',
            path: '/planning/project-manager-quote-list',
          }, {
            title: 'lang.cpq.quotation.label468',
          }],
        },
      },
      // 预报价规划报价单 - 创建
      {
        path: 'pre-quote-create',
        name: 'planningPreQuoteCreate',
        component: () => import('@/views/Planning/PreEquList/Create'),
        meta: {
          title: 'lang.cpq.menu.equipment.list.template',
          breadcrumb: [{
            title: 'lang.cpq.menu.planning.quote',
            path: '/planning/quote',
          }, {
            title: 'lang.cpq.label.preQuoteConfig',
          }],
        },
      },
      // 预报价规划报价单 - 详情
      {
        path: 'pre-quote-detail',
        name: 'planningPreQuoteDetail',
        component: () => import('@/views/Planning/PreEquList/Detail'),
        meta: {
          title: 'lang.cpq.menu.equipment.list.template',
          breadcrumb: [{
            title: 'lang.cpq.menu.planning.quote',
            path: '/planning/quote',
          }, {
            title: 'lang.cpq.quotation.label468',
          }],
        },
      },
    ],
  },
];
