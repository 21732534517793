export default [
  {
    path: 'account',
    name: 'account',
    component: () => import('@/views/Base/Base'),
    meta: {
      title: 'lang.cpq.menu.account',
      icon: 'calendar',
    },
    children: [
      // 账号管理
      {
        path: 'user-management',
        name: 'userManagement',
        component: () => import('@/views/Account/User/List.vue'),
        meta: {
          title: 'lang.cpq.menu.accountUser',
          icon: 'calendar',
          breadcrumb: [{ title: 'lang.cpq.menu.account', path: '/account/user-management' }, { title: 'lang.cpq.menu.accountUser' }],
        },
      },
      // 角色管理
      {
        path: 'role-management',
        name: 'roleManagement',
        component: () => import('@/views/Account/Role/List.vue'),
        meta: {
          title: 'lang.cpq.menu.accountRole',
          icon: 'calendar',
          breadcrumb: [{ title: 'lang.cpq.menu.account', path: '/account/role-management' }, { title: 'lang.cpq.menu.accountRole' }],
        },
      },
      // 机构管理
      {
        path: 'organizational-management',
        name: 'organizationalManagement',
        component: () => import('@/views/Account/Organization/List.vue'),
        meta: {
          title: 'lang.cpq.menu.deptlist',
          icon: 'calendar',
          breadcrumb: [{ title: 'lang.cpq.menu.account', path: '/account/organizational-management' }, { title: 'lang.cpq.menu.deptlist' }],
        },
      },
      // 权限管理
      {
        path: 'authority-management',
        name: 'authorityManagement',
        component: () => import('@/views/Account/Permission.vue'),
        meta: {
          title: 'lang.cpq.menu.accountPermission',
          icon: 'calendar',
          breadcrumb: [{ title: 'lang.cpq.menu.account', path: '/account/authority-management' }, { title: 'lang.cpq.menu.accountPermission' }],
        },
      },
    ],
  },
];
