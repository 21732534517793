import cn from '../i18n/cn';
import en from '../i18n/en';
import { getLanguage } from '@/i18n/i18n';
export default {
  computed: {
    lang() {
      return getLanguage();
    },
    langMap() {
      return this.lang === 'en' ? en : cn;
    },
  }
}
