import request from '@/utils/requset';

// 登录
export const login = (data) => request('/api/user/login', {
  method: 'post',
  body: data,
});
// 使用token登录获取信息接口
export const tokenLogin = (data) => request('/api/user/token-login', {
  method: 'post',
  body: data,
});
// 验证密码
export const validateOldPassword = (data) => request('/api/user/validateOldPassword', {
  method: 'post',
  body: data,
});
// 修改密码
export const updatePassword = (data) => request('/api/user/updatePassword', {
  method: 'post',
  body: data,
});
// 获取菜单
export const getMenuByLogin = (data) => request('/api/user/getMenuByLogin', {
  method: 'post',
  body: data,
  root: true,
});
// 获取角色类型
export const getRoleType = (data) => request('/api/user/getRoleType', {
  method: 'post',
  body: data,
});
// 获取角色列表
export const getRoleList = (data) => request('/api/user/roleList', {
  method: 'get',
  params: data,
});
// 获取用户列表
export const searchUser = (data) => request('/api/user/searchUser', {
  method: 'post',
  body: data,
});
// 获取用户列表过滤自己
export const searchUserTransferList = (data) => request('/api/approveWorkflow/transfer/user/list', {
  method: 'post',
  body: data,
});
// 根据登录者的区域获取该区域的所有销售
export const getRegionalSaleList = (data) => request('/api/common/getRegionalSaleList', {
  method: 'get',
  body: data,
});
// 获取部门树
export const getDepartTree = (data) => request('/api/department/getAllDepartmentTree', {
  method: 'get',
  params: data,
});
// 获取用户详细信息
export const getUserInfoById = (data) => request('/api/user/getUserInfoById', {
  method: 'get',
  params: data,
});
// 获取所有角色列表
export const getAllRoleList = (data) => request('/api/user/getAllRoles', {
  method: 'get',
  params: data,
});
// 获取角色详细信息
export const getRoleDetail = (data) => request('/api/user/getRole', {
  method: 'get',
  params: data,
});
// 获取所有菜单
export const getAllActiveMenus = (data) => request('/api/user/getAllActiveMenus', {
  method: 'get',
  params: data,
});
// 获取所有菜单
export const getDepartList = (data) => request('/api/department/list', {
  method: 'get',
  params: data,
});
// 获取登录信息
export const oAuth = (data) => request('/api/user/OASSOLogin', {
  method: 'post',
  body: data,
});
// 退出登录
export const logout = (data) => request('/api/user/logout', {
  method: 'post',
  body: data,
});
// 退出登录
export const getLoginUser = () => request('/api/user/getLoginUser');
// 通过oa登录时，会有systemModule参数，根据这个参数获取menu
export const getMenuBySystemModule = (data) => request('/api/user/getSSOMenuByLogin', {
  method: 'post',
  body: data,
  root: true,
});

// 获取用户相关项目列表
export const getProList = (data) => request('/api/user/search/project/list', {
  method: 'post',
  body: data,
});

// 检查邮箱正确性
export const checkEmail = (data) => request('/api/user/checkEmail', {
  method: 'post',
  body: data,
});

// 重置密码
export const sendResetPasswordEmail = (data) => request('/api/user/sendResetPasswordEmail', {
  method: 'post',
  body: data,
});

// 新增角色
export const addRole = (data) => request('/api/user/addRole', {
  method: 'post',
  body: data,
});
// 更新角色
export const updateRoleInfo = (data) => request('/api/user/updateRoleInfo', {
  method: 'post',
  body: data,
});
// 更新权限
export const setPermissions = (data) => request('/api/user/setPermissions', {
  method: 'post',
  body: data,
});
// 更新密码
export const resetPassword = (data) => request('/api/user/resetPassword', {
  method: 'post',
  body: data,
});

/* export const roleList = (data) => GET('/api/user/roleList', data);
export const getUserList = (data) => GET('/api/department/userList', data);
export const getAllRoles = () => GET('/api/user/getAllRoles');
export const getRole = (id) => GET(`/api/user/getRole?id=${id}`);

export const searchByName = (data) => GET('/api/user/searchByName', data);
export const addUser = (data) => POST('/api/user/addUser', data);
export const updateUserInfo = (data) => POST('/api/user/updateUserInfo', data);
export const disableUser = (data) => POST('/api/user/disableUser', data);
export const enableUser = (data) => POST('/api/user/enableUser', data);

export const addRole = (data) => POST('/api/user/addRole', data);
export const updateRoleInfo = (data) => POST('/api/user/updateRoleInfo', data);
export const deleteRole = (id) => DELETE(`/api/user/deleteRole?id=${id}`);
export const checkEmail = (data) => POST('/api/user/checkEmail', data);
export const sendResetPasswordEmail = (data) => POST('/api/user/sendResetPasswordEmail', data);
export const corporationList = (data) => GET('/api/user/corporation/list', data);
export const projectList = (data) => GET('/api/user/project/list', data);

export const getCorporationList = (data) => POST('/api/user/corporationList', data);
export const getAllRolesByRoleType = (data) => POST('/api/user/getAllRolesByRoleType', data);
export const getProList = (data) => POST('/api/user/search/project/list', data);
export const setPermissions = (data) => POST('/api/user/setPermissions', data);
export const getAllDepartment = (data) => GET('api/department/getAllDepartment', data);

// 通过oa登录时，会有systemModule参数，根据这个参数获取menu
export const getMenuBySystemModule = (data) => POST('/api/user/getSSOMenuByLogin', data); */

// export const getMenuBySystemModule = data => request('/api/user/getSSOMenuByLogin', {
//   method: 'post',
//   body: data,
// });
