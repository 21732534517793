export default [
  {
    path: 'opportunity',
    name: 'opportunity',
    component: () => import('@/views/Opportunity/List.vue'),
    meta: {
      title: 'lang.cpq.menu.quotation.management', // 报价列表
      icon: 'manage',
    },
  },
  {
    path: 'opportunity/create',
    name: 'opportunityCreate',
    component: () => import('@/views/Opportunity/Create.vue'),
    meta: {
      title: 'lang.cpq.label.businessOpportunityDetail', // 商机详情
      breadcrumb: [
        { title: 'lang.cpq.menu.quotation.management', path: '/opportunity' }, // 报价列表
        { title: 'lang.cpq.label.businessOpportunityDetail' }], // 商机详情
    },
  },
  {
    path: 'opportunity/trial',
    name: 'opportunityTrial',
    component: () => import('@/views/Quotation/Trial/List.vue'),
    meta: {
      title: 'lang.cpq.action.label1', // 试算报价
      breadcrumb: [
        { title: 'lang.cpq.menu.quotation.management', path: '/opportunity' }, // 报价列表
        { title: 'lang.cpq.action.label1' }], // 试算报价
    },
  },
  {
    path: '/trial/list',
    name: 'channelTrialList',
    component: () => import('@/views/Quotation/Trial/channelList.vue'),
    meta: {
      title: 'lang.cpq.menu.trial.list', // 试算列表
      breadcrumb: [
        { title: 'lang.cpq.menu.trial.list', path: '/trial/list' }], // 试算列表
    },
  },
  {
    path: 'opportunity/plan',
    name: 'opportunityPlan',
    component: () => import('@/views/Quotation/Plan/List.vue'),
    meta: {
      title: 'lang.cpq.action.label2', // 正式报价
      breadcrumb: [
        { title: 'lang.cpq.menu.quotation.management', path: '/opportunity' }, // 报价列表
        { title: 'lang.cpq.action.label2' }], // 正式报价
    },
  },
  // 为了显示选中菜单，增加相同页面，不同路由
  {
    path: 'opportunity/trial/edit/:opptSn?',
    name: 'opportunityTrialEdit',
    component: () => import('@/views/Quotation/Trial/Create.vue'),
    meta: {
      title: 'lang.cpq.action.sheet', // 编辑报价单
      breadcrumb: [
        { title: 'lang.cpq.menu.quotation.management', path: '/opportunity' }, // 报价列表
        { title: 'lang.cpq.action.label1' }], // 试算报价
    },
  },
  {
    path: 'opportunity/plan/edit/:opptSn?',
    name: 'opportunityPlanEdit',
    component: () => import('@/views/Quotation/Plan/Create.vue'),
    meta: {
      title: 'lang.cpq.action.sheet', // 编辑报价单
      breadcrumb: [
        { title: 'lang.cpq.menu.quotation.management', path: '/opportunity' }, // 报价管理
        { title: 'lang.cpq.action.label2' }], // 正式报价
    },
  },
  {
    path: 'opportunity/plan/view/:id?',
    name: 'opportunityPlanView',
    component: () => import('@/views/Quotation/Plan/Create.vue'),
    meta: {
      title: 'lang.cpq.business.label82', // 查看报价单
      breadcrumb: [
        { title: 'lang.cpq.menu.quotation.management', path: '/opportunity' }, // 报价列表
        { title: 'lang.cpq.action.label2' }], // 正式报价
    },
  },
  // 渠道商试算报价
  {
    path: 'channel/trial/edit/:opptSn?',
    name: 'channelTrialEdit',
    component: () => import('@/views/Quotation/Trial/ChannelCreate.vue'),
    meta: {
      title: 'lang.cpq.action.sheet', // 编辑报价单
      breadcrumb: [
        { title: 'lang.cpq.menu.trial.list', path: '/trial/list' }, // 试算列表
        { title: 'lang.cpq.action.label1' }], // 试算报价
    },
  },
  // 预报价
  {
    path: 'pre-opportunity/plan/edit/:opptSn?',
    name: 'preOpportunityPlanEdit',
    component: () => import('@/views/Quotation/PrePlan/Create.vue'),
    meta: {
      title: 'lang.cpq.action.sheet', // 编辑报价单
      breadcrumb: [
        { title: 'lang.cpq.menu.quotation.management', path: '/opportunity' }, // 报价管理
        { title: 'lang.cpq.action.label2' }], // 正式报价
    },
  },
  // 预报价
  {
    path: 'pre-opportunity/plan/view/:id?',
    name: 'preOpportunityPlanView',
    component: () => import('@/views/Quotation/PrePlan/Create.vue'),
    meta: {
      title: 'lang.cpq.business.label82', // 查看报价单
      breadcrumb: [
        { title: 'lang.cpq.menu.quotation.management', path: '/opportunity' }, // 报价列表
        { title: 'lang.cpq.action.label2' }], // 正式报价
    },
  },
];
