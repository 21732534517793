export default [
  {
    path: 'invoice',
    name: 'invoice',
    component: () => import('@/views/Base/Base'),
    meta: {
      title: 'lang.cpq.label.invoiceApplication', // 开票申请
      icon: 'approve',
    },
    children: [
      // 列表
      {
        path: 'list',
        name: 'invoiceList',
        component: () => import('@/views/Invoice/List'),
        meta: {
          title: 'lang.cpq.label.invoiceList',
          breadcrumb: [{
            title: 'lang.cpq.label.invoiceApplication',
            path: '/invoice/list',
          }, {
            title: 'lang.cpq.label.invoiceList',
          }],
        },
      },
      // 创建 / 修改 / 详情 / 审批 国内
      {
        path: 'china-create',
        name: 'chinaInvoiceCreate',
        component: () => import('@/views/Invoice/china/Create'),
        meta: {
          title: 'lang.cpq.label.invoiceApplication',
          breadcrumb: [{
            title: 'lang.cpq.label.invoiceList',
            path: '/invoice/list',
          }, {
            title: 'lang.cpq.label.invoiceApplication',
          }],
        },
      },
      // 创建 / 修改 / 详情 / 审批 海外
      {
        path: 'oversea-create',
        name: 'overseaInvoiceCreate',
        component: () => import('@/views/Invoice/oversea/Create'),
        meta: {
          title: 'lang.cpq.label.invoiceApplication',
          breadcrumb: [{
            title: 'lang.cpq.label.invoiceList',
            path: '/invoice/list',
          }, {
            title: 'lang.cpq.label.invoiceApplication',
          }],
        },
      },
      // 审批列表
      {
        path: 'approval-list',
        name: 'invoiceApprovalList',
        component: () => import('@/views/Invoice/ApprovalList'),
        meta: {
          title: 'lang.cpq.menu.equipment.list.template',
          breadcrumb: [{
            title: 'lang.cpq.label.invoiceApproval',
          }],
        },
      },
    ],
  },
];
