export default [
  {
    path: 'robot-price-trend',
    name: 'robotPriceTrend',
    component: () => import('@/views/RobotPriceTrend/RobotPriceTrend'),
    meta: {
      title: 'lang.cpq.menu.help.robotPriceTrend',
      icon: 'team',
    },
  },
];
