export default [
  {
    path: 'integrated-material',
    name: 'integratedMaterial',
    component: () => import('@/views/Base/Base'),
    meta: {
      title: 'lang.cpq.label.integratedMaterial', // 套装物料
      icon: 'approve',
    },
    children: [
      // 列表
      {
        path: 'list',
        name: 'integratedMaterialList',
        component: () => import('@/views/IntegratedMaterial/List'),
        meta: {
          title: 'lang.cpq.label.applyIntegratedMaterial',
        },
      },
      // 创建 / 修改 / 详情
      {
        path: 'create',
        name: 'integratedMaterialCreate',
        component: () => import('@/views/IntegratedMaterial/Create'),
        meta: {
          title: 'lang.cpq.label.createIntegratedMaterial',
          breadcrumb: [{
            title: 'lang.cpq.label.integratedMaterial',
            path: '/integrated-material/list',
          }, {
            title: 'lang.cpq.label.createIntegratedMaterial',
          }],
        },
      },
      // 料号转换
      {
        path: 'conversion',
        name: 'integratedMaterialConversion',
        component: () => import('@/views/IntegratedMaterial/Conversion.vue'),
        meta: {
          title: 'lang.cpq.label.materialNumberConversion',
        },
      },
    ],
  },
];
