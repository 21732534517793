/**
 * @param jsonData
 * */
export function paramsStringify(jsonData = {}) {
  const paramArray = [];
  let data = '';
  Object.keys(jsonData).forEach((i) => {
    switch (typeof jsonData[i]) {
      case 'string':
        data = jsonData[i].trim();
        break;
      case 'undefined':
        data = '';
        break;
      default:
        data = jsonData[i];
        break;
    }
    paramArray.push(`${i}=${encodeURIComponent(data)}`);
  });
  return paramArray.join('&');
}
/**
 * @param name
 * @param url
* */
export function getParameterByName(name, url = window.location.href) {
  // eslint-disable-next-line
  const newName = name.replace(/[\[\]]/g, '\\$&');
  const regex = new RegExp(`[?&]${newName}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(url);

  if (!results) return '';
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

// 密码加密
export function dataToSha(string) {
  const data = `${string}bh.L_-1q5LY@a78M9R.comScHool.6jS@Wo`;
  return sha256(sha256(data));
}

// 驼峰转换短横线
export function humpToLine(name) {
  return name.replace(/([A-Z])/g, '-$1').toLowerCase();
}
