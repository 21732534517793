export const equipmentLevelList = ['lang.cpq.level.one', 'lang.cpq.level.two', 'lang.cpq.level.three', 'lang.cpq.level.four'];
export const specialCodeList = [101, 102, 103, 104, 105, 106];
export const meterToFoot = 3.2808399;
export const raasQuotationUnitList = [
  {
    nameCn: '按月报价',
    nameEn: 'Monthly quotation',
    unitCn: '月',
    unitEn: 'Month',
    code: '0',
  },
  {
    nameCn: '按每月每台报价',
    nameEn: 'Quoted per unit per month',
    unitCn: '月/台',
    unitEn: 'Month/Units',
    code: '1',
  },
  {
    nameCn: '按出库单量报价',
    nameEn: 'Quote according to the order quantity',
    unitCn: '单',
    unitEn: 'Order',
    code: '2',
  },
  {
    nameCn: '按出库件数报价',
    nameEn: 'Quote according to the quantity of goods shipped',
    unitCn: '件',
    unitEn: 'Pieces',
    code: '3',
  },
];
const raasQuotationUnitMap = {};
raasQuotationUnitList.forEach((item) => {
  raasQuotationUnitMap[item.code] = item;
});

export const residentList = [
  'lang.cpq.quotation.label226', // 短期驻场
  'lang.cpq.quotation.label227', // 长期驻场
  'lang.cpq.quotation.label361', // 无需驻场
];

export const warrantyFeeDateList = [
  { id: 1, number: 2 },
  { id: 2, number: 2 },
  { id: 3, number: 4 },
  { id: 4, number: 4 },
  { id: 5, number: 4 },
];

const warrantyFeeDateMap = {};
warrantyFeeDateList.forEach((item) => {
  warrantyFeeDateMap[item.id] = item;
});

const equipmentAttributionList = [{
  id: '1',
  name: 'Geek+',
}, {
  id: '0',
  name: 'lang.cpq.quotation.label434',
}];
const equipmentAttributionMap = {};
equipmentAttributionList.forEach((item) => {
  equipmentAttributionMap[item.id] = item;
});

// 创建报价单 流程状态
const processStatusList = [{
  id: 0,
  name: 'lang.cpq.quotation.label442',
}, {
  id: 1,
  name: 'lang.cpq.quotation.label443',
}, {
  id: 2,
  name: 'lang.cpq.quotation.label287',
}, {
  id: 3,
  name: 'lang.cpq.quotation.label444',
}];
const processStatusMap = {};
processStatusList.forEach((item) => {
  processStatusMap[item.id] = item;
});

// 创建报价单 流程状态
const orderStatusList = [{
  id: 1,
  name: 'lang.cpq.quotation.label480',
}, {
  id: 2,
  name: 'lang.cpq.quotation.label442',
}];
const orderStatusMap = {};
orderStatusList.forEach((item) => {
  orderStatusMap[item.id] = item.name;
});

// 创建报价单 同步ERP状态
const syncErpStatusList = [{
  id: 0,
  name: 'lang.cpq.label.statusUnSynced', // 未同步
}, {
  id: 1,
  name: 'lang.cpq.quotation.label395', // 同步成功
}, {
  id: 2,
  name: 'lang.cpq.quotation.label396', // 同步失败
}];
const syncErpStatusMap = {};
syncErpStatusList.forEach((item) => {
  syncErpStatusMap[item.id] = item.name;
});

// 创建报价单 业务模式
const businessModelList = [{
  id: 1,
  name: 'lang.cpq.businessModelType1', // 销售
}, {
  id: 2,
  name: 'lang.cpq.businessModelType2', // 代运营
}, {
  id: 3,
  name: 'lang.cpq.businessModelType3', // 自营
}, {
  id: 4,
  name: 'lang.cpq.businessModelType4', // 无业务模式
}, {
  id: 5,
  name: 'lang.cpq.businessModelType5', // 服务
}, {
  id: 6,
  name: 'lang.cpq.businessModelType6', // 云仓
}, {
  id: 7,
  name: 'lang.cpq.businessModelType7', // RaaS运输
}];
const businessModelMap = {};
businessModelList.forEach((item) => {
  businessModelMap[item.id] = item.name;
});

// 仿真物料清单大类
const simuEquipmentTypeList = [
  { type: 'Robot', name: '机器人' },
  { type: 'Charge', name: '充电站' },
  { type: 'WorkStation', name: '工作站' },
  { type: 'SeedWall', name: '播种墙' },
  { type: 'Shelf', name: '货架' },
  { type: 'Server0', name: 'POC服务器' },
  {
    type: 'Server1', name: '服务器与网络配置 - 国内-低配', level: '1', region: 'china',
  },
  {
    type: 'Server2', name: '服务器与网络配置 - 国内-标配', level: '2', region: 'china',
  },
  {
    type: 'Server3', name: '服务器与网络配置 - 国内-高配', level: '3', region: 'china',
  },
  {
    type: 'Server4', name: '服务器与网络配置 - 海外-标配', level: '2', region: 'other',
  },
  {
    type: 'Server5', name: '服务器与网络配置 - 海外-高配', level: '3', region: 'other',
  },
  { type: 'QRCode', name: '二维码及打印耗材' },
  { type: 'ISD', name: '实施服务，软件开发' },
  { type: 'RMS', name: 'RMS' },
  { type: 'GPS', name: 'GPS' },
  { type: 'WMS', name: 'WMS' },
  { type: 'SingleWorkStation', name: '单点工位' },
];
const simuEquipmentTypeMap = {};
simuEquipmentTypeList.forEach((item, index) => {
  simuEquipmentTypeMap[item.type] = {
    ...item,
    index,
  };
});

// 仿真华为/优科选项
const simuApTypeList = [
  { type: 'YK', name: '优科' },
  { type: 'HW', name: '华为' },
];
const simuApTypeMap = {};
simuApTypeList.forEach((item, index) => {
  simuApTypeMap[item.type] = {
    ...item,
    index,
  };
});
// ap物料列表
const apEquipmentList = [
  '31.03.00003',
  '31.03.00044',
  '31.03.00009',
  '31.03.00045',
  '31.03.00011',
];
// ac物料列表
const acEquipmentList = [
  '31.04.00001',
  '31.04.00011',
];
// ap授权物料列表
const apLicenseEquipmentList = [
  '53.01.00110',
  '53.01.00160',
];

const regionList = [
  {
    name: '中国大区',
    code: 'CN',
    list: [],
  }, {
    name: '欧洲大区',
    code: 'EU',
    list: [
      { name: '德国', code: 'DE' },
      { name: '法国', code: 'FR' },
      { name: '波兰', code: 'PL' },
      { name: '其他欧洲地区', code: 'OTHER_EU_REGIONS' },
    ],
  }, {
    name: '亚太大区',
    code: 'AP',
    list: [
      { name: '韩国', code: 'KR' },
      { name: '泰国', code: 'TH' },
      { name: '日本', code: 'JP' },
      { name: '澳洲', code: 'AU' },
      { name: '台湾', code: 'TW' },
      { name: '其他亚太地区', code: 'OTHER_AP_REGIONS' },
    ],
  }, {
    name: '美洲大区',
    code: 'US',
    list: [
      { name: '美国', code: 'US' },
      { name: '加拿大', code: 'CA' },
      { name: '其他美洲地区', code: 'OTHER_US_REGIONS' },
    ],
  },
];

const serverList = [
  { code: 'L', name: '低配 - 国内' },
  { code: 'M', name: '标配 - 国内' },
  { code: 'H', name: '高配 - 国内' },
  { code: 'FM', name: '标配 - 海外' },
  { code: 'FH', name: '高配 - 海外' },
];

const equipmentAttrList = [
  {
    id: 1,
    name: 'lang.cpq.label.outsourcing1', // 外购
  },
  {
    id: 2,
    name: 'lang.cpq.label.outsourcing2', // 自制
  },
  {
    id: 3,
    name: 'lang.cpq.label.outsourcing3' // 委外
  },
  {
    id: 4,
    name: 'lang.cpq.label.outsourcing4', // 特征
  },
  {
    id: 5,
    name: 'lang.cpq.label.outsourcing5', // 虚拟
  },
  {
    id: 6,
    name: 'lang.cpq.businessModelType5', // 服务
  },
  {
    id: 7,
    name: 'lang.cpq.label.outsourcing6', // 一次性
  },
  {
    id: 9,
    name: 'lang.cpq.label.outsourcing7', // 配置
  },
  {
    id: 10,
    name: 'lang.cpq.label.outsourcing8', // 资产
  },
  {
    id: 11,
    name: 'lang.cpq.label.outsourcing9', // 费用
  },
  {
    id: 12,
    name: 'lang.cpq.label.outsourcing10', // 模型
  },
  {
    id: 13,
    name: 'lang.cpq.label.productSeries', // 产品系列
  },
];

const equipmentAttrMap = {};
equipmentAttrList.forEach((item) => {
  equipmentAttrMap[item.id] = item;
});

const customFunctionStructureList = [
  {
    id: 1,
    name: 'lang.cpq.label.customFunctionStructure1', // 机器人本体定制
  },
  {
    id: 2,
    name: 'lang.cpq.label.customFunctionStructure2', // 机器人上装定制_定制升顶
  },
  {
    id: 3,
    name: 'lang.cpq.label.customFunctionStructure3', // 机器人上装定制_定制滚筒
  },
  {
    id: 4,
    name: 'lang.cpq.label.customFunctionStructure4', // 机器人上装定制_定制单双举升
  },
  {
    id: 5,
    name: 'lang.cpq.label.customFunctionStructure5', // 机器人上装定制_定制单悬臂
  },
  {
    id: 6,
    name: 'lang.cpq.label.customFunctionStructure6', // 机器人上装定制_定制复合机器人
  },
  {
    id: 7,
    name: 'lang.cpq.label.customFunctionStructure7', // 附件定制
  },
];
const customFunctionStructureMap = {};
customFunctionStructureList.forEach((item) => {
  customFunctionStructureMap[item.id] = item;
});

// 套装物料产品线和sn前缀的映射关系
const integratedProductLineCodeMap = {
  'P': 'P',
  'O': 'PP',
  'R': 'RS',
  'X': 'SP',
  'S': 'S',
  'M': ['M', 'F', 'K'],
};

export {
  raasQuotationUnitMap,
  warrantyFeeDateMap,
  equipmentAttributionList,
  equipmentAttributionMap,
  processStatusList,
  processStatusMap,
  orderStatusList,
  orderStatusMap,
  syncErpStatusList,
  syncErpStatusMap,
  businessModelList,
  businessModelMap,
  simuEquipmentTypeList,
  simuEquipmentTypeMap,
  simuApTypeList,
  simuApTypeMap,
  apEquipmentList,
  acEquipmentList,
  apLicenseEquipmentList,
  regionList,
  serverList,
  equipmentAttrList,
  equipmentAttrMap,
  customFunctionStructureList,
  customFunctionStructureMap,
  integratedProductLineCodeMap,
};
