export default [
  {
    path: 'service-quotation',
    name: 'serviceQuotation',
    component: () => import('@/views/Base/Base'),
    meta: {
      title: 'lang.cpq.label.serviceQuotation', // 服务报价单
      icon: 'approve',
    },
    children: [
      // 列表
      {
        path: 'list',
        name: 'serviceQuotationList',
        component: () => import('@/views/ServiceQuotation/List'),
        meta: {
          title: 'lang.cpq.menu.equipment.list.template',
          breadcrumb: [{
            title: 'lang.cpq.label.serviceQuotation',
            path: '/service-quotation/list',
          }, {
            title: 'lang.cpq.label.serviceQuotationList',
          }],
        },
      },
      // 创建 / 修改 / 详情
      {
        path: 'create',
        name: 'serviceQuotationCreate',
        component: () => import('@/views/ServiceQuotation/Create'),
        meta: {
          title: 'lang.cpq.menu.equipment.list.template',
          breadcrumb: [{
            title: 'lang.cpq.label.serviceQuotation',
            path: '/service-quotation/list',
          }, {
            title: 'lang.cpq.label.serviceQuotation',
          }],
        },
      },
    ],
  },
];
